import Vue from 'vue'
import VueResource from 'vue-resource'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import i18n from '@/i18n';

switch (window.location.hostname) {
    case 'localhost':
        window.QUIZ_API_ROOT = 'https://quizapi.elumiti.kz'
        window.API_ROOT = 'https://api1000bala.elumiti.kz'
        // window.API_ROOT = 'http://myn-bala.loc'
        window.QUIZ_ROOT = 'https://quiz.elumiti.kz'
        break;
    case 'quiz.elumiti.kz':
        window.QUIZ_API_ROOT = 'https://quizapi.elumiti.kz'
        window.API_ROOT = 'https://api1000bala.elumiti.kz'
        window.QUIZ_ROOT = 'https://quiz.elumiti.kz'
        // window.QUIZ_API_ROOT = 'https://quiz-api.elumiti.kz'
        // window.API_ROOT = 'https://mynbala-api.elumiti.kz'
        // window.QUIZ_ROOT = 'https://quiz.elumiti.kz'
        break;
    case 'mynbala.elbasyacademy.kz':
        window.QUIZ_API_ROOT = 'https://apiquizmyn.elbasyacademy.kz'
        window.API_ROOT = 'https://apimynbala.elbasyacademy.kz'
        window.QUIZ_ROOT = 'https://quizmyn.elbasyacademy.kz'
        break;
    default:
        window.QUIZ_API_ROOT = 'https://quizapi.elumiti.kz'
        window.API_ROOT = 'https://api1000bala.elumiti.kz'
        window.QUIZ_ROOT = 'https://quiz.elumiti.kz'
    // window.QUIZ_API_ROOT = 'https://quiz-api.elumiti.kz'
    // window.API_ROOT = 'https://mynbala-api.elumiti.kz'
    // window.QUIZ_ROOT = 'https://quiz.elumiti.kz'
}


Vue.use(VueResource)

Vue.http.options.crossOrigin = true
Vue.http.options.xhr = {withCredentials: true}
Vue.http.options.root = API_ROOT
// Vue.http.options.emulateJSON = true

Vue.http.options.progress = function (event) {
    if (event.lengthComputable) {
        NProgress.set(event.loaded / event.total)
    }
}

Vue.http.interceptors.push((request, next) => {
    NProgress.start()
    if (localStorage.getItem('session_id')) {
        request.headers.set('session-id', localStorage.getItem('session_id'))
    }

    if (localStorage.getItem('token')) {
        request.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    }
    next((response) => {
        NProgress.done()
        if (response.status > 400 && response.body.region) {
            Vue.toastr({
              description: i18n.t('login-reg', {arrayRegions: response.body.region}),
              type: 'error'
            })

            return
        }
        if (response.status > 400 && !response.url.includes("auth-available")) {
            Vue.toastr({
                description: (response.body.message) ? response.body.message : `Произошла ошибка, попробуйте позднее (Код: ${response.status})`,
                type: 'error'
            })
        }
        if (response.status == 401) {
            localStorage.setItem('session_id', '');
            localStorage.setItem('token', '');
            localStorage.setItem('user', '');
            window.location = '/';
        }
        return response
    })
})

export const GetIIN = Vue.resource(API_ROOT + '/api/iin')

export const GetIINByEmail = Vue.resource(API_ROOT + '/api/iinbyemail')

export const Auth = Vue.resource(API_ROOT + '/api/login')


export const AuthTeach = Vue.resource(API_ROOT + '/api/teacher/login')


export const Logout = Vue.resource(API_ROOT + '/api/logout')

export const SendSMS = Vue.resource(API_ROOT + '/api/sms')

export const SendEmail = Vue.resource(API_ROOT + '/api/teacher/verification-code/send')

export const Api = API_ROOT

// export const getWinners = fetch(`${API_ROOT}/api/winners/2020`).then((response) => response.json()).then((data) => { console.log(data) })
