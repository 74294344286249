<template>
  <div>
    <nav class="component-navbar main-navbar navbar navbar-expand-lg">
      <div class="container">
        <button :class="{open: !collapsed}" type="button" class="navbar-toggler collapsed burger-btn-hint" @click="collapsed=!collapsed"
                v-clickaway="clickaway">
          <span class="navbar-toggler-icon"><div class="menu-btn-burger"></div></span>
        </button>
        <!--        <a href="https://test.elumiti.kz" class="navbar-brand"><img width="44" src="/images/logo_el_umiti.png"></a>-->
        <!-- <a href="https://elbasyacademy.kz" target="_blank" class="navbar-brand p-0" ><img width="44" src="/images/logo_el_umiti.png"></a> -->
        <a href="https://elumiti.kz/" target="_blank" class="navbar-brand p-0"><img width="84" style="padding: 10px"
                                                                                         src="/images/EL_logo.svg"></a>
        <router-link to="/" class="navbar-brand p-0"><img width="75" src="/images/1000-BALA-logo.svg"></router-link>
        <!-- <a href="https://elbasyacademy.kz" target="_blank" class="navbar-brand p-0 mobile-only"><img width="44" height="57.14" src="/images/logo_el_umiti.png"></a> -->
        <a class="broadcast mobile-only" v-if="online === 1" :href="url" target="_blanc">
          <img src="/images/broadcast.svg">
          <p>LIVE</p>
        </a>


        <a href="#" class="navbar-brand special-view-button-mobile"><img width="22" src="/images/special-view.svg"></a>

        <div class="navbar-collapse desktop-menu" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item hidden-md mx-0">
              <a class="nav-link" href="https://www.instagram.com/el_umiti.kz/" target="_blank"><i
                  class="ig-icon"></i></a>
            </li>
            <li class="nav-item mx-0">
              <a class="nav-link" href="https://www.facebook.com/elumiti" target="_blank"><i class="fb-icon"></i></a>
            </li>
            <li class="nav-item mx-0">
              <a class="nav-link" href="https://www.youtube.com/channel/UC9nAqSXFXJSJi5qe1xBTNTA" target="_blank"><i
                  class="yt-icon"></i></a>
            </li>
            <!--
            <li class="nav-item mx-0 dropdown">
              <a class="nav-link dropdown-toggle special-view-button" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="special-view-icon"></i>
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Another action</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#">Something else here</a>
              </div>
            </li>
            -->

            <li class="nav-item">
              <a class="nav-link" href="tel:87273100258"><i class="phone-icon"></i>8 727 310 02 58</a>
            </li>
            <li v-if="!logged" class="nav-item signin-hint">
              <router-link tag="a" to="/signin" class="nav-link" href="#">{{ $t('navbar.signin') }}</router-link>
            </li>
            <li v-if="!logged" class="nav-item signup-hint">
              <a href="#" @click.prevent="routeSignupHandler" class="nav-link">{{ $t('navbar.signup') }}</a>
            </li>
            <li v-if="logged" class="nav-item">
              <a href="/profile" class="nav-link">{{ $t('navbar.profile') }}</a>
              <!--              <router-link tag="a" to="/profile" class="nav-link">{{ $t('navbar.profile') }}</router-link>-->
            </li>
            <li v-if="logged" class="nav-item">
              <a @click.prevent="logoutMethod" class="nav-link cursor-pointer">{{ $t('navbar.signout') }}</a>
            </li>
            <li class="nav-item locale-link-desktop mr-0" :class="{'lang-active': $i18n.locale == 'ru'}">
              <a class="nav-link cursor-pointer" @click="switchLocale('ru')"><span>RU</span></a>
            </li>
            <li class="nav-item locale-link-desktop mx-0" :class="{'lang-active': $i18n.locale == 'kz'}">
              <a class="nav-link cursor-pointer" @click="switchLocale('kz')"><span>KZ</span></a>
            </li>
            <div class="broadcast-section">
              <a class="broadcast" v-if="online === 1" :href="url" target="_blanc">
                <img src="/images/broadcast.svg">
                <p>{{ $t('navbar.broadcast') }}</p>
              </a>
            </div>
            <!--            <li class="nav-item locale-link-desktop ml-0" :class="{'lang-active': $i18n.locale == 'en'}">-->
            <!--              <a class="nav-link cursor-pointer" @click="switchLocale('en')"><span>EN</span></a>-->
            <!--            </li>-->
          </ul>
        </div>
      </div>
    </nav>
    <div class="nav-scroller" :class="{collapse: collapsed}">
      <div class="container">
        <nav class="nav">
          <li class="nav-item mx-0" :class="{'active': $route.path === '/'}">
            <router-link tag="a" class="nav-link" to="/">{{ $t('navbar.home') }}</router-link>
          </li>
          <li class="nav-item mx-0" :class="{'active': $route.path === '/about'}">
            <router-link tag="a" class="nav-link" to="/about">{{ $t('navbar.about') }}</router-link>
          </li>
          <li class="nav-item mx-0" :class="{'active': $route.path === '/practice-tests'}">
            <router-link tag="a" class="nav-link trainer-hint" to="/practice-tests">{{ $t('trial-tests') }}</router-link>
          </li>
          <li class="nav-item mx-0" :class="{'active': $route.path === '/for-teachers'}">
            <router-link tag="a" class="nav-link signup-teacher-hint" to="/for-teachers">{{ $t('navbar.for-teachers') }}</router-link>
          </li>
          <!--          <li class="nav-item mx-0" :class="{'active': $route.path === '/instructions'}">-->
          <!--            <router-link tag="a" class="nav-link" to="/instructions">{{ $t('navbar.instructions') }}</router-link>-->
          <!--          </li>-->
          <li class="nav-item mx-0" :class="{'active': $route.path === '/for-winners'}">
            <router-link tag="a" class="nav-link" to="/for-winners">{{ $t('navbar.for-winners') }}</router-link>
          </li>
          <li class="nav-item mx-0" :class="{'active': $route.path === '/faq'}">
            <router-link tag="a" class="nav-link" to="/faq">{{ $t('navbar.faq') }}</router-link>
          </li>

          <li v-if="!logged" class="nav-item hidden-desktop " :disabled="tech">
            <router-link tag="a" to="/signin" class="nav-link signin-mobile-hint" href="#">{{ $t('navbar.signin') }}</router-link>
          </li>
          <li v-if="!logged" class="nav-item hidden-desktop " :disabled="tech">
            <a @click.prevent="routeSignupHandler" class="nav-link signup-mobile-hint">{{ $t('navbar.signup') }}</a>
          </li>
          <li v-if="logged" class="nav-item hidden-desktop ">
            <a href="/profile" class="nav-link">{{ $t('navbar.profile') }}</a>
            <!--              <router-link tag="a" to="/profile" class="nav-link">{{ $t('navbar.profile') }}</router-link>-->
          </li>
          <li v-if="logged" class="nav-item hidden-desktop ">
            <a @click.prevent="logoutMethod" class="nav-link cursor-pointer">{{ $t('navbar.signout') }}</a>
          </li>
          <li class="nav-item mx-0 hidden-desktop nav-phone-number">
            <a class="nav-link" href="#"><i class="phone-icon"></i>8 727 310 02 58</a>
          </li>
        </nav>
        <div class="nav-mobile-apps">
          <h5>{{ $t('navbar.install-app') }}</h5>
          <div class="mobile-apps-wrap">
            <a href="#"><img src="/images/google-badge.svg" width="168" alt=""></a>
            <a href="#"><img src="/images/appstore-badge.svg" width="168" alt=""></a>
          </div>
        </div>
      </div>
    </div>
    <ul class="locale-links-mobile clearfix">
      <li class="nav-item mr-0" :class="{'lang-active': $i18n.locale == 'ru'}">
        <a class="nav-link cursor-pointer" @click="switchLocale('ru')"><span>RU</span></a>
      </li>
      <li class="nav-item mx-0" :class="{'lang-active': $i18n.locale == 'kz'}">
        <a class="nav-link cursor-pointer" @click="switchLocale('kz')"><span>KZ</span></a>
      </li>
      <!--      <li class="nav-item ml-0" :class="{'lang-active': $i18n.locale == 'en'}">-->
      <!--        <a class="nav-link cursor-pointer" @click="switchLocale('en')"><span>EN</span></a>-->
      <!--      </li>-->
    </ul>
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import Vue from 'vue';
import EnjoyHint from 'xbs-enjoyhint/src/enjoyhint';
import 'xbs-enjoyhint/enjoyhint.css';

export default {
  name: 'Navbar',
  data() {
    return {
      logged: false,
      collapsed: true,
      online: 0,
      url: ''
    }
  },

  computed: {
    ...mapGetters({
      tech: 'isTech'
    })
  },
  methods: {
    startTour() {
      if (window.location.pathname !== '/') {
        return;
      }

      const enjoyhintInstance = new EnjoyHint({});

      if(window.innerWidth > 1000) {
        enjoyhintInstance.setSteps([
          // {
          //   'next .signup-hint': this.$t('enjoy_hint_text_1'),
          //   'prevButton': { text: this.$t('enjoy_hint_prev') },
          //   'nextButton': { text: this.$t('enjoy_hint_next') },
          //   'skipButton': { text: this.$t('enjoy_hint_skip') },
          // },
          {
            'next .signin-hint': this.$t('enjoy_hint_text_2'),
            'prevButton': { text: this.$t('enjoy_hint_prev') },
            'nextButton': { text: this.$t('enjoy_hint_next') },
            'skipButton': { text: this.$t('enjoy_hint_skip') },
          },
          {
            'next .signup-teacher-hint': this.$t('enjoy_hint_text_3'),
            'prevButton': { text: this.$t('enjoy_hint_prev') },
            'nextButton': { text: this.$t('enjoy_hint_next') },
            'skipButton': { text: this.$t('enjoy_hint_skip') },
          },
          {
            'next .trainer-hint': this.$t('enjoy_hint_text_4'),
            'prevButton': { text: this.$t('enjoy_hint_prev') },
            'nextButton': { text: this.$t('enjoy_hint_close') },
            'skipButton': { text: this.$t('enjoy_hint_skip') },
          }
        ]);
      } else {
        enjoyhintInstance.setSteps([
          {
            'click .burger-btn-hint': this.$t('enjoy_hint_text_menu'),
            'prevButton': { text: this.$t('enjoy_hint_prev') },
            'nextButton': { text: this.$t('enjoy_hint_next') },
            'skipButton': { text: this.$t('enjoy_hint_skip') },
          },
          // {
          //   'next .signup-mobile-hint': this.$t('enjoy_hint_text_1'),
          //   'prevButton': { text: this.$t('enjoy_hint_prev') },
          //   'nextButton': { text: this.$t('enjoy_hint_next') },
          //   'skipButton': { text: this.$t('enjoy_hint_skip') },
          // },
          {
            'next .signin-mobile-hint': this.$t('enjoy_hint_text_2'),
            'prevButton': { text: this.$t('enjoy_hint_prev') },
            'nextButton': { text: this.$t('enjoy_hint_next') },
            'skipButton': { text: this.$t('enjoy_hint_skip') },
          },
          {
            'next .signup-teacher-hint': this.$t('enjoy_hint_text_3'),
            'prevButton': { text: this.$t('enjoy_hint_prev') },
            'nextButton': { text: this.$t('enjoy_hint_next') },
            'skipButton': { text: this.$t('enjoy_hint_skip') },
          },
          {
            'next .trainer-hint': this.$t('enjoy_hint_text_4'),
            'prevButton': { text: this.$t('enjoy_hint_prev') },
            'nextButton': { text: this.$t('enjoy_hint_close') },
            'skipButton': { text: this.$t('enjoy_hint_skip') },
          }

        ]);
      }


      enjoyhintInstance.run();

      this.$nextTick(() => {
        setTimeout(() => {
          const buttons = document.querySelectorAll(
              '.enjoyhint_next_btn, .enjoyhint_prev_btn, .enjoyhint_close_btn'
          );
          buttons.forEach((btn) => {
            btn.addEventListener('click', (event) => {
              event.stopPropagation();
            });
          });
        }, 200);
      });
    },
    switchLocale(lang) {
      localStorage.setItem('locale', lang);
      this.$i18n.locale = lang;
      this.$router.go(this.$router.currentRoute)
    },
    logoutMethod() {
      this.logged = false;
      this.logout()
    },
    clickaway() {
      this.collapsed = true
    },
    async routeSignupHandler() {
      try {
        const res = await this.$http.get(`${window.API_ROOT}/api/check-time`)
        if (res.body.code === 200) {
          this.$router.push({ name: 'signup-first-step' })
        }
        if (res.body.code === 403) {
          Vue.toastr({
            message: '',
            // description: this.$t('navbar.registration-alert').replace('year', new Date().getFullYear()),
            description: this.$t('navbar.registration-alert'),
            type: 'info'
          });
        }
      } catch (error) {
        Vue.toastr({
          message: this.$t('error'),
          description: error,
          type: 'error'
        });
      }
    },
    async getTranslation() {
      const response = await this.$http.get(`${API_ROOT}/api/broadcasts`)
      this.online = response.body[0].active
      this.url = response.body[0].url
    },
    ...mapActions({
      'logout': 'logout'
    })
  },
  mounted() {
    this.logged = !(localStorage.getItem('token') === null || localStorage.getItem('token') === '');
    this.getTranslation();
    this.startTour()
  }
}
</script>


