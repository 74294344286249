<template>
  <div class="sidebar profile-sidebar">
    <div class="profile">
      <div class="avatar" :style="`background-image: ${avatar ? `url(${avatar})` : '/images/Intersect.png'}`">
        <!-- <img class="avatar" :src="avatar ? avatar : '/images/Intersect.png'"> -->
      </div>
      <div class="username">{{ user.first_name }} {{ user.last_name }}</div>
      <div v-if="user.education" class="role text-center">{{
          (user.education.status == 0) ? $t('sidebar.member') : ''
        }}
      </div>
    </div>
    <div class="profile-sidebar-menu">
      <!--      <a v-if="showPoleButton" @click="openModal()" class="polled-modal-button">{{ $t('pole-modal.survey') }}</a>-->
      <router-link tag="a" to="/cabinet" class="cabinet" :class="{'active': $route.path === '/cabinet'}">
        {{ $t('sidebar.cabinet') }}
      </router-link>
      <router-link tag="a" to="/profile" class="profile" :class="{'active': $route.path === '/profile'}">
        {{ $t('sidebar.profile') }}
      </router-link>
      <router-link tag="a" to="/testing" class="testing" :class="{'active': $route.path === '/testing'}">
        {{ $t('sidebar.testing') }}
      </router-link>
      <router-link tag="a" to="/trial-tests" class="profile" :class="{'active': $route.path === '/trial-tests'}">
        {{ $t('trial-tests') }}
      </router-link>
<!--      <router-link tag="a" to="/my_teachers" class="testing" :class="{'active': $route.path === '/my_teachers'}">-->
<!--        {{ $t('profile.my_teachers') }}-->
<!--      </router-link>-->
      <!--      <router-link tag="a" to="/results" class="testing" :class="{'active': $route.path === '/results'}">-->
      <!--        {{ $t('results') }}-->
      <!--      </router-link>-->
      <router-link
          v-if="user.second_stage_type === 'ss_winner' && user.school_enroll_available"
          tag="a"
          to="/schools-list"
          class="claims"
          :class="{'active': $route.path === '/schools-list'}"
      >{{ $t('sidebar.schools-list') }}
      </router-link>
      <router-link tag="a" to="/notification" class="notifications"
                   :class="{'active': $route.path === '/notification' || $route.path === '/notification-result', 'notifications-news': hasNewNotification}">
        {{ $t('sidebar.notifications') }}
      </router-link>
      <router-link tag="a" to="/settings" class="settings" :class="{'active': $route.path === '/settings'}">
        {{ $t('sidebar.settings') }}
      </router-link>
<!--      <router-link tag="a" to="/my-results" class="profile" :class="{'active': $route.path === '/my-results'}">-->
<!--        {{ $t('my_results_1')}}-->
<!--      </router-link>-->

      <!--      <a href="#" class="claims">{{ $t('sidebar.schools-list') }}</a>-->
      <!--      <a href="#" class="notifications">{{ $t('sidebar.notifications') }}</a>-->
      <!--      <a href="#" class="settings">{{ $t('sidebar.settings') }}</a>-->
      <!--      <a href="#" class="achievements">Мои олимпиады</a>-->
      <b-modal id="poledModal" hide-footer hide-header no-close-on-backdrop size="lg" @shown="onShown" @hidden="onHidden">
        <div class="pole-modal-content" id="dialog">
          <!--          <button @click="$bvModal.hide('poledModal')" type="button" class="close" data-dismiss="modal"-->
          <!--                  aria-label="Close">-->
          <!--            <span aria-hidden="true"></span>-->
          <!--          </button>-->
          <div class="pole-modal-title">{{ $t('profile.informed-modal.title') }}</div>
          <div class="" v-html="$t('poled-modal-text')">
          </div>
          <h1 class="title-polemodal">
            {{ $t('footer.terms-of-use') }}
          </h1>
          <div v-html=" $t('pol-term-of-use') ">

          </div>
          <el-checkbox class="confirm-pole-modal" style="  white-space: unset;" v-model="informedCheckbox"><strong>{{
              $t('pol-term-of-use-checkbox')
            }}</strong>
          </el-checkbox>
        </div>
      </b-modal>
      <b-modal id="poledModal-2-ru" hide-footer hide-header no-close-on-backdrop size="lg" @shown="onShownFormRu" @hidden="onHiddenFormRu">
        <div class="pole-modal-content" id="dialog">
          <!--          <button @click="$bvModal.hide('poledModal-2-ru')" type="button" class="close" data-dismiss="modal"-->
          <!--                  aria-label="Close">-->
          <!--            <span aria-hidden="true"></span>-->
          <!--          </button>-->
          <form style="margin-top: 2rem" ref="formElement">

            <div class="polemodalQuestions">Насколько сложной была для тебя олимпиада «Мың бала»?</div>
            <el-radio-group v-model="Questions.level">
              <el-radio label="Очень сложно">{{ $t('pole-modal.firstAnswers.answer-1') }}</el-radio>
              <el-radio label="Сложно">{{ $t('pole-modal.firstAnswers.answer-2') }}</el-radio>
              <el-radio label="Не знаю">{{ $t('pole-modal.firstAnswers.answer-3') }}</el-radio>
              <el-radio label="Легко">{{ $t('pole-modal.firstAnswers.answer-4') }}</el-radio>
              <el-radio label="Очень легко">{{ $t('pole-modal.firstAnswers.answer-5') }}</el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">Откуда ты узнал/узнала об олимпиаде?</div>
            <el-radio-group v-model="Questions.foundOutOlympiad">
              <el-radio label="По телевизору">По телевизору</el-radio>
              <el-radio label="В интернете">В интернете</el-radio>
              <el-radio label="От учителей">От учителей</el-radio>
              <el-radio label="От родных">От родных</el-radio>
              <el-radio label="__other_option__">{{ $t('pole-modal.other') }}: <input v-model="Questions.textFoundOutOlympiad"
                                                                                      class="pole-modal-input"></input>
              </el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">
              Какие были проблемы с регистрацией на олимпиаду?
            </div>
            <el-radio-group v-model="Questions.problemsWithOlympiad">
              <el-radio label="Не было доступа к интернету">Не было доступа к интернету</el-radio>
              <el-radio label="Не знал как регистрироваться">Не знал как регистрироваться</el-radio>
              <el-radio label="Не было проблем">Не было проблем</el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">
              Какие были проблемы с прохождением первого этапа олимпиады?
            </div>
            <el-radio-group v-model="Questions.problemsWithPassing">
              <el-radio label="Плохой интернет">Плохой интернет</el-radio>
              <el-radio label="Неудобно проходить с телефона">Неудобно проходить с телефона</el-radio>
              <el-radio label="Неудобно проходить с компьютера">Неудобно проходить с компьютера</el-radio>
              <el-radio label="Сложные вопросы">Сложные вопросы</el-radio>
              <el-radio label="Не было проблем">Не было проблем</el-radio>
              <el-radio label="__other_option__">{{ $t('pole-modal.other') }}: <input v-model="Questions.textProblemsWithPassing"
                                                                                      class="pole-modal-input"></input>
              </el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">Знаешь ли ты о том, что можно было подготовится и пройти пробный тест олимпиады «Мың бала» (платно)?</div>
            <el-radio-group v-model="Questions.trialTest">
              <el-radio label="Да, я проходил (проходила)">Да, я проходил (проходила)</el-radio>
              <el-radio label="Да я знаю, но не проходил (проходила)">Да я знаю, но не проходил (проходила)</el-radio>
              <el-radio label="Нет">Нет</el-radio>
            </el-radio-group>


            <div class="polemodalQuestions"> Представь, что ты стал/стала победителем олимпиады Мын бала? Какой приз ты
              бы хотел/хотела получить за победу в олимпиаде?
            </div>
            <el-radio-group v-model="Questions.win">
              <el-radio label="Зачисление на учебу в лицей, гимназию, Дарын, БИЛ">
                Зачисление на учебу в лицей, гимназию, Дарын, БИЛ
              </el-radio>
              <el-radio label="Переехать в областной центр, столицу">
                Переехать в областной центр, столицу
              </el-radio>
              <el-radio label="Получить подарок">Получить подарок</el-radio>
              <el-radio label="Получить дополнительное обучение по предметам (репетиторы, онлайн-уроки)">
                Получить дополнительное обучение по предметам (репетиторы, онлайн-уроки)
              </el-radio>
              <el-radio label="Получить доступ к интересным курсам (майнкрафт, мобилография и т.п.)">
                Получить доступ к интересным курсам (майнкрафт, мобилография и т.п.)
              </el-radio>
              <el-radio label="Денежный приз">
                Денежный приз
              </el-radio>
              <el-radio label="Поехать на олимпиаду в другой город или страну">
                Поехать на олимпиаду в другой город или страну
              </el-radio>
              <el-radio label="__other_option__">{{ $t('pole-modal.other') }}: <input v-model="Questions.textWin"
                                                                                      class="pole-modal-input"></input>
              </el-radio>
            </el-radio-group>

            <div :class="{'error-pole-modal':Questions.schoolProblems.length>3}" class="polemodalQuestions">Какие
              проблемы ты видишь в учебе? (можно выбрать 3 варианта)
            </div>
            <el-checkbox-group v-model="Questions.schoolProblems">
              <el-checkbox label="Дорога до школы"></el-checkbox>
              <el-checkbox label="Проблемы с одеждой"></el-checkbox>
              <el-checkbox label="В школе нет хороших компьютеров, мебели, спортивных снарядов"></el-checkbox>
              <el-checkbox label="Я не понимаю некоторые тему уроков"></el-checkbox>
              <el-checkbox label="Мне неинтересно сидеть на уроках"></el-checkbox>
              <el-checkbox label="Нет денег на репетиторов или платные кружки"></el-checkbox>
              <el-checkbox label="Нет времени на усердную учебу"></el-checkbox>
              <el-checkbox label="Некоторые темы предметов слишком сложные"></el-checkbox>
              <el-checkbox label="У меня нет друзей"></el-checkbox>
              <el-checkbox label="Некому помочь мне с учебой"></el-checkbox>
              <el-checkbox label="Я не хочу учиться"></el-checkbox>
            </el-checkbox-group>
            <span class="error-pole-modal"
                  v-if="Questions.schoolProblems.length>3">Максимальное число вариантов: 3</span>

            <div class="polemodalQuestions">Кто обычно помогает тебе с уроками, если нужна помощь?</div>
            <el-radio-group v-model="Questions.schoolHelper">
              <el-radio label="Мама">Мама</el-radio>
              <el-radio label="Папа или мама, у кого есть время">Папа или мама, у кого есть время</el-radio>
              <el-radio label="Другие родственники (бабушка, старший брат и т.п)">Другие родственники (бабушка, старший
                брат и т.п)
              </el-radio>
              <el-radio label="Репетитор">Репетитор</el-radio>
              <el-radio label="Учитель в дополнительное время">Учитель в дополнительное время</el-radio>
              <el-radio label="Продленка в школе">Продленка в школе</el-radio>
              <el-radio label="Я делаю уроки сам/сама">Я делаю уроки сам/сама</el-radio>
            </el-radio-group>


            <div :class="{'error-pole-modal':Questions.participateInLifeClass.length>3}" class="polemodalQuestions">
              Как твои родители (законные представители) участвуют в жизни твоего класса и школы? (можно выбрать 3 варианта)
            </div>
            <el-checkbox-group v-model="Questions.participateInLifeClass">
              <el-checkbox label="Ничего из перечисленного"></el-checkbox>
              <el-checkbox label="Принимают участие в школьных мероприятиях (праздниках, поздравлениях, концертах)"></el-checkbox>
              <el-checkbox label="Обсуждают с учителем мои успехи и неудачи в учебе"></el-checkbox>
              <el-checkbox label="Ходят на родительское собрание"></el-checkbox>
              <el-checkbox label="Состоят в родительском комитете"></el-checkbox>
              <el-checkbox label="Собирают или сдают деньги на нужды класса и школы"></el-checkbox>
              <el-checkbox label="__other_option__">{{ $t('pole-modal.other') }}: <input v-model="Questions.textParticipateInLifeClass"
                                                                                         class="pole-modal-input"></input>
              </el-checkbox>
            </el-checkbox-group>
            <span class="error-pole-modal"
                  v-if="Questions.participateInLifeClass.length>3">Максимальное число вариантов: 3</span>

            <div class="polemodalQuestions">Зачем ты ходишь в школу? (ответь самостоятельно и честно)</div>
            <input v-model="Questions.questionGoToSchool" class="pole-modal-input"></input>

            <div class="polemodalQuestions">Как ты думаешь, твой класс дружный?</div>
            <el-radio-group v-model="Questions.friendlyClass">
              <el-radio label="Да, мы все дружим">Да, мы все дружим</el-radio>
              <el-radio label="В основном да, но есть ребята с которыми не хотят дружить">В основном да, но есть ребята
                с которыми не хотят дружить
              </el-radio>
              <el-radio label="В классе дружим маленькими группами">В классе дружим маленькими группами</el-radio>
              <el-radio label="У меня есть несколько друзей, но класс не дружный">У меня есть несколько друзей, но класс
                не дружный
              </el-radio>
              <el-radio label="У нас каждый сам по себе">У нас каждый сам по себе</el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">Учителя бывают с тобой строги?</div>
            <el-radio-group v-model="Questions.friendlyTeacher">
              <el-radio label="Да, иногда слишком">Да, иногда слишком</el-radio>
              <el-radio label="Да, в основном это справедливо">Да, в основном это справедливо</el-radio>
              <el-radio label="Со мной нет, но с другими учениками бывает">Со мной нет, но с другими учениками бывает
              </el-radio>
              <el-radio label="Нет, учителя не ругают">Нет, учителя не ругают</el-radio>
              <el-radio label="Не знаю">Не знаю</el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">Какой урок в школе твой любимый и почему?</div>
            <input v-model="Questions.favoriteSubject" class="pole-modal-input"></input>

            <div class="polemodalQuestions">Какой урок в школе тебе не нравится и  почему?</div>
            <input v-model="Questions.notFavoriteSubject" class="pole-modal-input"></input>

            <div class="polemodalQuestions">Какие оценки у тебя в школе?</div>
            <el-radio-group v-model="Questions.gradesAtSchool">
              <el-radio label="Только пятерки">Только пятерки</el-radio>
              <el-radio label="Четверки и пятерки">Четверки и пятерки</el-radio>
              <el-radio label="В основном пятерки и четверки, но есть несколько троек">В основном пятерки и четверки, но есть несколько троек</el-radio>
              <el-radio label="В основном одни тройки">В основном одни тройки</el-radio>
              <el-radio label="Бывают двойки">Бывают двойки</el-radio>
            </el-radio-group>

            <div :class="{'error-pole-modal':Questions.helpClassmates.length>3}" class="polemodalQuestions">
              Как ты считаешь, что нужно делать учителям, чтобы ты и твои одноклассники лучше учились? (можно выбрать 3 варианта)
            </div>
            <el-checkbox-group v-model="Questions.helpClassmates">
              <el-checkbox label="Быть строгими"></el-checkbox>
              <el-checkbox label="Применять игры, приключения"></el-checkbox>
              <el-checkbox label="Требовать своевременного выполнения уроков"></el-checkbox>
              <el-checkbox label="Давать творческие задания"></el-checkbox>
              <el-checkbox label="Предлагать обсуждения и дискуссии"></el-checkbox>
              <el-checkbox label="Быть внимательным к просьбам и предложениям учеников"></el-checkbox>
              <el-checkbox label="Развивать личные качества ученика"></el-checkbox>
              <el-checkbox label="Хвалить учеников"></el-checkbox>
              <el-checkbox label="__other_option__">{{ $t('pole-modal.other') }}: <input v-model="Questions.textHelpClassmates"
                                                                                         class="pole-modal-input"></input>
              </el-checkbox>
            </el-checkbox-group>
            <span class="error-pole-modal"
                  v-if="Questions.helpClassmates.length>3">Максимальное число вариантов: 3</span>

            <div class="polemodalQuestions">
              Учителя внимательны к твоим вопросам, просьбам, успеваемости?
            </div>
            <el-radio-group v-model="Questions.teachersAttentiveToYou">
              <el-radio label="Да">Да</el-radio>
              <el-radio label="В основном да">В основном да</el-radio>
              <el-radio label="Не знаю">Не знаю</el-radio>
              <el-radio label="В основном нет">В основном нет</el-radio>
              <el-radio label="Нет">Нет</el-radio>
              <el-radio label="Не понимаю вопрос">Не понимаю вопрос</el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">
              Обсуждаете ли вы дома, в семье твои успехи или трудности?
            </div>
            <el-radio-group v-model="Questions.discussAtHome">
              <el-radio label="Да">Да</el-radio>
              <el-radio label="В основном да">В основном да</el-radio>
              <el-radio label="Не знаю">Не знаю</el-radio>
              <el-radio label="В основном нет">В основном нет</el-radio>
              <el-radio label="Нет">Нет</el-radio>
              <el-radio label="Не понимаю вопрос">Не понимаю вопрос</el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">
              Проходил (проходила) ли ты тестирование своих талантов и способностей «Qabilet»?
            </div>
            <el-radio-group v-model="Questions.useQabilet">
              <el-radio label="Да">Да</el-radio>
              <el-radio label="Нет, не знаю что это такое">Нет, не знаю что это такое</el-radio>
              <el-radio label="Знаю или слышал от других, но не проходил (проходила)">Знаю или слышал от других, но не проходил (проходила)</el-radio>
            </el-radio-group>

            <!--            <div class="polemodalQuestions">-->
            <!--              Твои родители (законные представители) и учителя знают твои результаты тестирования?-->
            <!--            </div>-->
            <!--            <el-radio-group v-model="Questions.parentsKnowResult">-->
            <!--              <el-radio label="Родители знают">Родители знают</el-radio>-->
            <!--              <el-radio label="Учитель знает">Учитель знает</el-radio>-->
            <!--              <el-radio label="Учитель и родители знают">Учитель и родители знают</el-radio>-->
            <!--              <el-radio label="Я только знаю">Я только знаю</el-radio>-->
            <!--              <el-radio label="Никто не знает">Никто не знает</el-radio>-->
            <!--            </el-radio-group>-->




            <div :class="{'error-pole-modal':Questions.howSpendFreeTime.length>3}" class="polemodalQuestions">
              Как обычно ты проводишь свободное время? (можно выбрать 3 варианта)
            </div>
            <el-checkbox-group v-model="Questions.howSpendFreeTime">
              <el-checkbox label="Встречаюсь с друзьями"></el-checkbox>
              <el-checkbox label="Провожу время в интернете"></el-checkbox>
              <el-checkbox label="Хожу в гости"></el-checkbox>
              <el-checkbox label="Смотрю телевизор"></el-checkbox>
              <el-checkbox label="Дополнительно учусь, занимаюсь с репетитором"></el-checkbox>
              <el-checkbox label="Помогаю по хозяйству"></el-checkbox>
              <el-checkbox label="Хожу в спортивную или творческую секцию"></el-checkbox>
              <el-checkbox label="Читаю книги"></el-checkbox>
              <el-checkbox label="__other_option__">{{ $t('pole-modal.other') }}: <input v-model="Questions.textHowSpendFreeTime"
                                                                                         class="pole-modal-input"></input>
              </el-checkbox>
            </el-checkbox-group>
            <span class="error-pole-modal"
                  v-if="Questions.howSpendFreeTime.length>3">Максимальное число вариантов: 3</span>

            <div :class="{'error-pole-modal':Questions.internetActivity.length>3}" class="polemodalQuestions">
              Что обычно ты делаешь в интернете? (можно выбрать 3 варианта)
            </div>
            <el-checkbox-group v-model="Questions.internetActivity">
              <el-checkbox label="Читаю новости"></el-checkbox>
              <el-checkbox label="Ищу полезную информацию (интересы, хобби, волнующие вопросы)"></el-checkbox>
              <el-checkbox label="Общаюсь в социальных сетях"></el-checkbox>
              <el-checkbox label="Смотрю блогеров"></el-checkbox>
              <el-checkbox label="Играю в онлайн-игры"></el-checkbox>
              <el-checkbox label="Учусь (прохожу уроки, делаю домашнее задание)"></el-checkbox>
              <el-checkbox label="Слушаю или скачиваю музыку"></el-checkbox>
              <el-checkbox label="Слушаю подкасты и аудиокниги"></el-checkbox>
              <el-checkbox label="Веду страницу в тик-ток, инстаграм и т.п."></el-checkbox>
              <el-checkbox label="Смотрю фильмы"></el-checkbox>
              <el-checkbox label="Делаю покупки в интернете"></el-checkbox>
              <el-checkbox label="Слушаю и смотрю контент на религиозные темы"></el-checkbox>
              <el-checkbox label="Прохожу онлайн-курсы, занимаюсь в кружках и секциях онлайн"></el-checkbox>
            </el-checkbox-group>
            <span class="error-pole-modal"
                  v-if="Questions.internetActivity.length>3">Максимальное число вариантов: 3</span>

            <div class="polemodalQuestions">
              В какие игры ты играешь?
            </div>
            <el-radio-group v-model="Questions.whatGamesPlay">
              <el-radio label="Компьютерные">Компьютерные</el-radio>
              <el-radio label="Настольные">Настольные</el-radio>
              <el-radio label="Мобильные игры">Мобильные игры</el-radio>
              <el-radio label="Спортивные, развлечения с друзьями">Спортивные, развлечения с друзьями</el-radio>
              <el-radio label="Я не играю в игры">Я не играю в игры</el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">
              Напиши названия игр, в которые ты играешь
            </div>
            <input v-model="Questions.favoriteGames" class="pole-modal-input"></input>

            <div class="polemodalQuestions">
              Хотел/хотела бы ты учиться, проходить школьные предметы в формате игр?
            </div>
            <el-radio-group v-model="Questions.schoolSubjectsInGames">
              <el-radio label="Да">Да</el-radio>
              <el-radio label="Скорее да, чем нет">Скорее да, чем нет</el-radio>
              <el-radio label="Скорее нет, чем да">Скорее нет, чем да</el-radio>
              <el-radio label="Нет">Нет</el-radio>
              <el-radio label="Не понимаю, что это значит">Не понимаю, что это значит</el-radio>
            </el-radio-group>


            <div class="polemodalQuestions">
              Используешь ли ты возможности искусственного интеллекта (ChatGPT, Azure AI, Google DeepMind)?
            </div>
            <el-radio-group v-model="Questions.useAI">
              <el-radio label="Да, постоянно">Да, постоянно</el-radio>
              <el-radio label="Да, пробывал (пробывала)">Да, пробывал (пробывала)</el-radio>
              <el-radio label="Нет">Нет</el-radio>
              <el-radio label="Я не знаю что это">Я не знаю что это</el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">
              Знаешь ли ты про онлайн-школу BirgeOqu?
            </div>
            <el-radio-group v-model="Questions.knowBirgeOqu">
              <el-radio label="Нет">Нет</el-radio>
              <el-radio label="Да">Да</el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">
              Есть ли у тебя условия, чтобы учиться  онлайн?
            </div>
            <div class="polemodalQuestions">
              Компьютер
            </div>
            <el-radio-group v-model="Questions.onlineComputer">
              <el-radio label="Нет">Нет</el-radio>
              <el-radio label="Да">Да</el-radio>
            </el-radio-group>
            <div class="polemodalQuestions">
              Интернет с хорошей скоростью
            </div>
            <el-radio-group v-model="Questions.onlineGoodInternet">
              <el-radio label="Нет">Нет</el-radio>
              <el-radio label="Да">Да</el-radio>
            </el-radio-group>
            <div class="polemodalQuestions">
              Рабочее место
            </div>
            <el-radio-group v-model="Questions.onlineWorkPlace">
              <el-radio label="Нет">Нет</el-radio>
              <el-radio label="Да">Да</el-radio>
            </el-radio-group>
            <div class="polemodalQuestions">
              Свободное время
            </div>
            <el-radio-group v-model="Questions.onlineComputerFreeTime">
              <el-radio label="Нет">Нет</el-radio>
              <el-radio label="Да">Да</el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">Посещаешь кружки или секции?</div>
            <el-radio-group v-model="Questions.sections">
              <el-radio label="Да (посещаю офлайн)">Да (посещаю офлайн)</el-radio>
              <el-radio label="Да (посещаю онлайн)">Да (посещаю онлайн)</el-radio>
              <el-radio label="Да (онлайн и офлайн)">Да (онлайн и офлайн)</el-radio>
              <el-radio label="Я хочу, но нет возможностей">Я хочу, но нет возможностей</el-radio>
              <el-radio label="Я не знаю какие есть кружки и секции">Я не знаю какие есть кружки и секции</el-radio>
              <el-radio label="Мне это неинтересно">Мне это неинтересно</el-radio>
              <el-radio label="Нет">Нет</el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">
              Что может вызвать у тебя интерес и может побудить тебя записаться на дополнительный онлайн-кружок?
            </div>
            <el-radio-group v-model="Questions.interestAdditionalOnlineCourses">
              <el-radio label="Низкая цена или бесплатно">
                Низкая цена или бесплатно
              </el-radio>
              <el-radio label="Подарки и вознаграждение">
                Подарки и вознаграждение
              </el-radio>
              <el-radio label="Будущая профессия">
                Будущая профессия
              </el-radio>
              <el-radio label="Реклама">
                Реклама
              </el-radio>
              <el-radio label="Совет друга, учителя">
                Совет друга, учителя
              </el-radio>
              <el-radio label="Саморазвитие">
                Саморазвитие
              </el-radio>
              <el-radio label="Полезные знания для основной учебы">
                Полезные знания для основной учебы
              </el-radio>
              <el-radio label="Интерес к теме кружка">
                Интерес к теме кружка
              </el-radio>
              <el-radio label="__other_option__">{{ $t('pole-modal.other') }}: <input v-model="Questions.textInterestAdditionalOnlineCourses"
                                                                                      class="pole-modal-input"></input>
              </el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">
              Если есть возможность выбора, то какое дополнительное образование тебе больше нравится?
            </div>
            <el-radio-group v-model="Questions.additionalEducation">
              <el-radio label="Техническое (программирование, моделирование, инжиниринг и т.п.)">Техническое (программирование, моделирование, инжиниринг и т.п.)</el-radio>
              <el-radio label="Гуманитарное (история, география, социология и т.п.)">Гуманитарное (история, география, социология и т.п.)</el-radio>
              <el-radio label="Подготовка к школьным олимпиадам">Подготовка к школьным олимпиадам</el-radio>
              <el-radio label="Спортивное (шахматы, шашки, туризм, основы единоборств и т.п.)">Спортивное (шахматы, шашки, туризм, основы единоборств и т.п.)</el-radio>
              <el-radio label="Развитие личности (мотивация, психология, ораторство и т.п.)">Развитие личности (мотивация, психология, ораторство и т.п.)</el-radio>
              <el-radio label="Финансы (основы предпринимательства, личные расходы, криптовалюты и т.п.)">Финансы (основы предпринимательства, личные расходы, криптовалюты и т.п.)</el-radio>
              <el-radio label="Творчество (дизайн, компьютерная графика, музыка и т.п.)">Творчество (дизайн, компьютерная графика, музыка и т.п.)</el-radio>
              <el-radio label="Языки (английский, казахский и т.п.)">Языки (английский, казахский и т.п.)</el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">
              Выбери наиболее понравившийся кружок-онлайн (Технологии)
            </div>
            <el-radio-group v-model="Questions.favoriteSectionOnline">
              <el-radio label="Блочное программирование (Kodu, Minecraft, Roblox)">Блочное программирование (Kodu, Minecraft, Roblox)</el-radio>
              <el-radio label="Программирование (Go, Python)">Программирование (Go, Python)</el-radio>
              <el-radio label="Разработка сайтов">Разработка сайтов</el-radio>
              <el-radio label="Робототехника">Робототехника</el-radio>
              <el-radio label="Создание игр (Unity и т.п.)">Создание игр (Unity и т.п.)</el-radio>
              <el-radio label="Ничего не нравится">Ничего не нравится</el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">
              Выбери наиболее понравившийся кружок-онлайн (подготовка к школьным олимпиадам)
            </div>
            <el-radio-group v-model="Questions.preparationForSchoolOlympiads">
              <el-radio label="Математика">Математика</el-radio>
              <el-radio label="Химия">Химия</el-radio>
              <el-radio label="Физика">Физика</el-radio>
              <el-radio label="История">История</el-radio>
              <el-radio label="География">География</el-radio>
              <el-radio label="Языки">Языки</el-radio>
              <el-radio label="Информатика">Информатика</el-radio>
              <el-radio label="Ничего не нравится">Ничего не нравится</el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">
              Выбери наиболее понравившийся кружок-онлайн (Развитие личности)
            </div>
            <el-radio-group v-model="Questions.personalDevelopment">
              <el-radio label="Мотивация и общее развитие">Мотивация и общее развитие</el-radio>
              <el-radio label="Психология">Психология</el-radio>
              <el-radio label="Ораторство">Ораторство</el-radio>
              <el-radio label="Дебаты">Дебаты</el-radio>
              <el-radio label="Ничего не нравится">Ничего не нравится</el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">
              Выбери наиболее понравившийся кружок-онлайн (Финансы)
            </div>
            <el-radio-group v-model="Questions.financeCourses">
              <el-radio label="Основы предпринимательства">Основы предпринимательства</el-radio>
              <el-radio label="Личные расходы">Личные расходы</el-radio>
              <el-radio label="Криптовалюты и блокчейн">Криптовалюты и блокчейн</el-radio>
              <el-radio label="Ничего не нравится">Ничего не нравится</el-radio>
            </el-radio-group>



            <div :class="{'error-pole-modal':Questions.profession.length>3}" class="polemodalQuestions">Кем ты хочешь
              стать в будущем? (можно выбрать 3 варианта)
            </div>
            <el-checkbox-group v-model="Questions.profession">
              <el-checkbox label="Президентом, депутатом, министром">Президентом, депутатом, министром</el-checkbox>
              <el-checkbox label="Бизнесменом">Бизнесменом</el-checkbox>
              <el-checkbox label="Ученым">Ученым</el-checkbox>
              <el-checkbox label="Полицейским, военным">Полицейским, военным</el-checkbox>
              <el-checkbox label="Художником">Художником</el-checkbox>
              <el-checkbox label="Врачом">Врачом</el-checkbox>
              <el-checkbox label="Юристом">Юристом</el-checkbox>
              <el-checkbox label="Финансистом, работать в банке">Финансистом, работать в банке</el-checkbox>
              <el-checkbox label="Киберспортсменом, геймером">Киберспортсменом, геймером</el-checkbox>
              <el-checkbox label="Актером, певцом">Актером, певцом</el-checkbox>
              <el-checkbox label="Космонавтом">Космонавтом</el-checkbox>
              <el-checkbox label="Музыкантом, танцором">Музыкантом, танцором</el-checkbox>
              <el-checkbox label="Учителем">Учителем</el-checkbox>
              <el-checkbox label="Телеведущим, журналистом">Телеведущим, журналистом</el-checkbox>
              <el-checkbox label="Блогером, тиктокером, ютубером">Блогером, тиктокером, ютубером</el-checkbox>
              <el-checkbox label="IT-специалистом">IT-специалистом</el-checkbox>
              <el-checkbox label="Спортсменом">Спортсменом</el-checkbox>
              <el-checkbox label="Не знаю">Не знаю</el-checkbox>
              <el-checkbox label="__other_option__">{{ $t('pole-modal.other') }}: <input
                  v-model="Questions.textProfession" class="pole-modal-input"></input></el-checkbox>
            </el-checkbox-group>
            <span class="error-pole-modal" v-if="Questions.profession.length>3">Максимальное число вариантов: 3</span>

            <div :class="{'error-pole-modal':Questions.successfulInFuture.length>3}" class="polemodalQuestions">
              Что может помочь тебе стать успешным в будущем? (можно выбрать 3 варианта)
            </div>
            <el-checkbox-group v-model="Questions.successfulInFuture">
              <el-checkbox label="Переезд в другой город">Переезд в другой город</el-checkbox>
              <el-checkbox label="Помощь учителей">Помощь учителей</el-checkbox>
              <el-checkbox label="Ремонт и техника в школе">Ремонт и техника в школе</el-checkbox>
              <el-checkbox label="Переезд в другую страну">Переезд в другую страну</el-checkbox>
              <el-checkbox label="Занятия в компьютерном кружке (в том числе робототехника)">Занятия в компьютерном кружке (в том числе робототехника)</el-checkbox>
              <el-checkbox label="Помощь репетитора">Помощь репетитора</el-checkbox>
              <el-checkbox label="Поступление в школу-лицей, БИЛ, Дарын">Поступление в школу-лицей, БИЛ, Дарын</el-checkbox>
              <el-checkbox label="Больше внимания родителей">Больше внимания родителей</el-checkbox>
              <el-checkbox label="Занятия в спортивных секциях или творческих кружках">Занятия в спортивных секциях или творческих кружках</el-checkbox>
              <el-checkbox label="Больше внимания учителей">Больше внимания учителей</el-checkbox>
              <el-checkbox label="Изучение языков">Изучение языков</el-checkbox>
              <el-checkbox label="__other_option__">{{ $t('pole-modal.other') }}: <input
                  v-model="Questions.textSuccessfulInFuture" class="pole-modal-input"></input></el-checkbox>
            </el-checkbox-group>
            <span class="error-pole-modal" v-if="Questions.successfulInFuture.length>3">Максимальное число вариантов: 3</span>

            <div class="button-polemodal-wrapper">
              <el-button @click="sendForm()" type="primary">{{ $t('pole-modal.send') }}</el-button>
            </div>
          </form>
        </div>
      </b-modal>
      <b-modal id="poledModal-2-kz" hide-footer hide-header no-close-on-backdrop size="lg" @shown="onShownFormKz" @hidden="onHiddenFormKz">
        <div class="pole-modal-content" id="dialog">
          <!--          <button @click="$bvModal.hide('poledModal-2-ru')" type="button" class="close" data-dismiss="modal"-->
          <!--                  aria-label="Close">-->
          <!--            <span aria-hidden="true"></span>-->
          <!--          </button>-->
          <form style="margin-top: 2rem" ref="formElement">




            <div class="polemodalQuestions">«Мың бала» олимпиадасы сен үшін қаншалықты қиын болды?</div>
            <el-radio-group v-model="Questions.level">
              <el-radio label="Өте қиын">Өте қиын</el-radio>
              <el-radio label="Қиын">Қиын</el-radio>
              <el-radio label="Білмеймін">Білмеймін</el-radio>
              <el-radio label="Оңай">Оңай</el-radio>
              <el-radio label="Өте жеңіл">Өте жеңіл</el-radio>
            </el-radio-group>


            <div class="polemodalQuestions">
              Олимпиада туралы қайдан білдің?
            </div>
            <el-radio-group v-model="Questions.foundOutOlympiad">
              <el-radio label="Теледидардан">Теледидардан</el-radio>
              <el-radio label="Интернеттен">Интернеттен</el-radio>
              <el-radio label="Мұғалімнен">Мұғалімнен</el-radio>
              <el-radio label="Туысқандардан">Туысқандардан</el-radio>
              <el-radio label="__other_option__">{{ $t('pole-modal.other') }}: <input v-model="Questions.textFoundOutOlympiad"
                                                                                      class="pole-modal-input"></input>
              </el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">
              Олимпиадаға тіркелгенде қандай мәселе туындады?
            </div>
            <el-radio-group v-model="Questions.problemsWithOlympiad">
              <el-radio label="Интернетке қосыла алмадым">Интернетке қосыла алмадым</el-radio>
              <el-radio label="Қалай тіркелу керегін білмедім">Қалай тіркелу керегін білмедім</el-radio>
              <el-radio label="Ешқандай мәселе болған жоқ">Ешқандай мәселе болған жоқ</el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">
              Олимпиаданың бірінші кезеңінде қандай мәселе болды?
            </div>
            <el-radio-group v-model="Questions.problemsWithPassing">
              <el-radio label="Интернет нашар болды">Интернет нашар болды</el-radio>
              <el-radio label="Телефоннан кірген ыңғайсыз">Телефоннан кірген ыңғайсыз</el-radio>
              <el-radio label="Компьютерден кірген ыңғайсыз">Компьютерден кірген ыңғайсыз</el-radio>
              <el-radio label="Күрделі сұрақтар">Күрделі сұрақтар</el-radio>
              <el-radio label="Ешқандай мәселе болмады">Ешқандай мәселе болмады</el-radio>
              <el-radio label="__other_option__">{{ $t('pole-modal.other') }}: <input v-model="Questions.textProblemsWithPassing"
                                                                                      class="pole-modal-input"></input>
              </el-radio>
            </el-radio-group>


            <div class="polemodalQuestions">"Мың бала" олимпиадасына  дайындалу үшін тестілеу сынағынан (ақылы)өтуге болатынын білесің бе?  </div>
            <el-radio-group v-model="Questions.trialTest">
              <el-radio label="Иә, өттім">Иә, өттім</el-radio>
              <el-radio label="Иә, білемін, бірақ өтпедім">Иә, білемін, бірақ өтпедім</el-radio>
              <el-radio label="Жоқ">Жоқ</el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">
              Сені «Мың бала» олимпиадасының жеңімпазы болды деп елестетейік. Олимпиадада жеңіске жеткенің үшін қандай сыйлық алғың келер еді?
            </div>
            <el-radio-group v-model="Questions.win">
              <el-radio label="Лицей, гимназия, БИЛ, Дарынға қабылдану">
                Лицей, гимназия, БИЛ, Дарынға қабылдану
              </el-radio>
              <el-radio label="Облыс орталығына, астанаға көшу">
                Облыс орталығына, астанаға көшу
              </el-radio>
              <el-radio label="Сыйлық алу">Сыйлық алу</el-radio>
              <el-radio label="Пәндер бойынша қосымша білім алу (репетитормен, онлайн сабақтар)">
                Пәндер бойынша қосымша білім алу (репетитормен, онлайн сабақтар)
              </el-radio>
              <el-radio label="Қызықты курстарға қол жеткізу (майнкрафт, мобилография т.б.)">
                Қызықты курстарға қол жеткізу (майнкрафт, мобилография т.б.)
              </el-radio>
              <el-radio label="Ақшалай сыйлық">
                Ақшалай сыйлық
              </el-radio>
              <el-radio label="Басқа қалаға немесе елде өтетін олимпиадаға бару">
                Басқа қалаға немесе елде өтетін олимпиадаға бару
              </el-radio>
              <el-radio label="__other_option__">{{ $t('pole-modal.other') }}: <input v-model="Questions.textWin"
                                                                                      class="pole-modal-input"></input>
              </el-radio>
            </el-radio-group>

            <div :class="{'error-pole-modal':Questions.schoolProblems.length>3}" class="polemodalQuestions">
              Оқу кезінде саған қиындық тудыратын мәселе неде? (3 жауап таңдауға болады)
            </div>
            <el-checkbox-group v-model="Questions.schoolProblems">
              <el-checkbox label="Тереңдеп, мұқият отырып оқуға уақытым жоқ"></el-checkbox>
              <el-checkbox label="Мектепке баратын жол"></el-checkbox>
              <el-checkbox label="Киімге қатысты мәселелер"></el-checkbox>
              <el-checkbox label="Менің оқығым келмейді"></el-checkbox>
              <el-checkbox label="Мектепте жақсы компьютерлер, жиһаздар, спорттық жабдықтар жоқ"></el-checkbox>
              <el-checkbox label="Маған сабақтың кейбір тақырыптары түсініксіз"></el-checkbox>
              <el-checkbox label="Маған сабақта отыру қызықсыз"></el-checkbox>
              <el-checkbox label="Менің достарым жоқ"></el-checkbox>
              <el-checkbox label="Оқуыма көмектесетін адам жоқ"></el-checkbox>
              <el-checkbox label="Кейбір пән тақырыптары мен үшін тым күрделі"></el-checkbox>
              <el-checkbox label="Репетиторларға немесе ақылы сабақтарға қаржы жоқ"></el-checkbox>
            </el-checkbox-group>
            <span class="error-pole-modal"
                  v-if="Questions.schoolProblems.length>3">3 нұсқаны таңдауға болады</span>

            <div class="polemodalQuestions">Әдетте оқуыңа көмек қажет болса, сабақтарыңа кім көмектеседі?</div>
            <el-radio-group v-model="Questions.schoolHelper">
              <el-radio label="Мектептегі ұзартылған қосымша сабақтарында">Мектептегі ұзартылған қосымша сабақтарында</el-radio>
              <el-radio label="Басқа туыстарым (әжем, ағам және т.б.)">Басқа туыстарым (әжем, ағам және т.б.)</el-radio>
              <el-radio label="Мұғаліммен қосымша уақытта">Мұғаліммен қосымша уақытта
              </el-radio>
              <el-radio label="Үй тапсырмасын өзім жасаймын">Үй тапсырмасын өзім жасаймын</el-radio>
              <el-radio label="Репетитор">Репетитор</el-radio>
              <el-radio label="Анам">Анам</el-radio>
              <el-radio label="Бос уақыты бар анам немесе әкем">Бос уақыты бар анам немесе әкем</el-radio>
            </el-radio-group>


            <div :class="{'error-pole-modal':Questions.participateInLifeClass.length>3}" class="polemodalQuestions">
              Сенің ата-анаң (заңды өкілдерің) сыныбың мен мектеп өміріне араласа ма? (3 жауап таңдауға болады)
            </div>
            <el-checkbox-group v-model="Questions.participateInLifeClass">
              <el-checkbox label="Мұғаліммен менің оқудағы жетістіктерім мен сәтсіздіктерімді талқылайды"></el-checkbox>
              <el-checkbox label="Мектептің іс-шараларына (мерекелерге, құттықтауларға, концерттерге) қатысады"></el-checkbox>
              <el-checkbox label="Сынып пен мектептің қажеттіліктеріне ақша жинайды немесе тапсырады"></el-checkbox>
              <el-checkbox label="Ата-аналар жиналысына барады"></el-checkbox>
              <el-checkbox label="Жоғарыда айтылғандардың ешқайсысы"></el-checkbox>
              <el-checkbox label="Ата-аналар комитетінде мүше"></el-checkbox>
              <el-checkbox label="__other_option__">{{ $t('pole-modal.other') }}: <input v-model="Questions.textParticipateInLifeClass"
                                                                                         class="pole-modal-input"></input>
              </el-checkbox>
            </el-checkbox-group>
            <span class="error-pole-modal"
                  v-if="Questions.participateInLifeClass.length>3">3 нұсқаны таңдауға болады</span>

            <div class="polemodalQuestions">Мектепке не үшін барасың? (өз бетіңше және адал (шынайы) жауап бер)</div>
            <input v-model="Questions.questionGoToSchool" class="pole-modal-input"></input>

            <div class="polemodalQuestions">Сенің сыныбың өзара тату ма?</div>
            <el-radio-group v-model="Questions.friendlyClass">
              <el-radio label="Иә, біз бәріміз доспыз">Иә, біз бәріміз доспыз</el-radio>
              <el-radio label="Негізінен иә, бірақ дос болғысы келмейтін балалар бар">
                Негізінен иә, бірақ дос болғысы келмейтін балалар бар
              </el-radio>
              <el-radio label="Сыныпта кішігірім топ-топ болып достасамыз">Сыныпта кішігірім топ-топ болып достасамыз</el-radio>
              <el-radio label="Менің бірнеше достарым бар, бірақ сыныптағы оқушылар бір-бірімен тату емес">Менің бірнеше достарым бар, бірақ сыныптағы оқушылар бір-бірімен тату емес
              </el-radio>
              <el-radio label="Бізде әрқайсысы өз бетінше">Бізде әрқайсысы өз бетінше</el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">Мұғалімдер саған қатал ма?</div>
            <el-radio-group v-model="Questions.friendlyTeacher">
              <el-radio label="Иә, кейде тым артық">Иә, кейде тым артық</el-radio>
              <el-radio label="Иә, бірақ бұл әділ деп ойлаймын">Иә, бірақ бұл әділ деп ойлаймын</el-radio>
              <el-radio label="Маған қатал емес, бірақ басқа оқушыларға қаталдау">Маған қатал емес, бірақ басқа оқушыларға қаталдау
              </el-radio>
              <el-radio label="Жоқ, мұғалімдер ұрыспайды">Жоқ, мұғалімдер ұрыспайды</el-radio>
              <el-radio label="Білмеймін">Білмеймін</el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">
              Мектептегі сүйікті сабағың қандай? Неге?
            </div>
            <input v-model="Questions.favoriteSubject" class="pole-modal-input"></input>

            <div class="polemodalQuestions">Саған мектепте қандай сабақ ұнамайды? Неге?</div>
            <input v-model="Questions.notFavoriteSubject" class="pole-modal-input"></input>

            <div class="polemodalQuestions">Мектептегі бағаларың қандай?</div>
            <el-radio-group v-model="Questions.gradesAtSchool">
              <el-radio label="Тек бестік">Тек бестік</el-radio>
              <el-radio label="Төрт және бес">Төрт және бес</el-radio>
              <el-radio label="Негізінен бес және төрт, бірақ бірнеше үштік бар">Негізінен бес және төрт, бірақ бірнеше үштік бар</el-radio>
              <el-radio label="Негізінен үш">Негізінен үш</el-radio>
              <el-radio label="Екіліктерім бар">Екіліктерім бар</el-radio>
            </el-radio-group>

            <div :class="{'error-pole-modal':Questions.helpClassmates.length>3}" class="polemodalQuestions">
              Сен және сенің сыныптастарың оқуды үздік оқу үшін мұғалімдер не істеу керек деп ойлайсың? (3 жауап таңдауға болады)
            </div>
            <el-checkbox-group v-model="Questions.helpClassmates">
              <el-checkbox label="Оқушыларды мақтау"></el-checkbox>
              <el-checkbox label="Оқушылардың өтініштері мен ұсыныстарына назар аудару"></el-checkbox>
              <el-checkbox label="Талқылаулар мен пікірталастар"></el-checkbox>
              <el-checkbox label="Қатал болу"></el-checkbox>
              <el-checkbox label="Ойындарды, шытырман оқиғаны қолдану"></el-checkbox>
              <el-checkbox label="Шығармашылық тапсырмалар"></el-checkbox>
              <el-checkbox label="Оқушының тұлғалық қасиеттерін дамыту"></el-checkbox>
              <el-checkbox label="Сабақтардың уақытылы аяқталуын талап ету"></el-checkbox>
              <el-checkbox label="__other_option__">{{ $t('pole-modal.other') }}: <input v-model="Questions.textHelpClassmates"
                                                                                         class="pole-modal-input"></input>
              </el-checkbox>
            </el-checkbox-group>
            <span class="error-pole-modal"
                  v-if="Questions.helpClassmates.length>3">3 нұсқаны таңдауға болады</span>

            <div class="polemodalQuestions">
              Мұғалімдер сенің сұрақтарыңа, өтініштеріңе, үлгеріміңе назар аудара ма?
            </div>
            <el-radio-group v-model="Questions.teachersAttentiveToYou">
              <el-radio label="Иә">Иә</el-radio>
              <el-radio label="Негізінен иә">Негізінен иә</el-radio>
              <el-radio label="Білмеймін">Білмеймін</el-radio>
              <el-radio label="Негізінен жоқ">Негізінен жоқ</el-radio>
              <el-radio label="Жоқ">Жоқ</el-radio>
              <el-radio label="Мен сұрақты түсінбедім">Мен сұрақты түсінбедім</el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">
              Сен өз жетістіктеріңді немесе қиындықтарыңды үйде, отбасыңда талқылайсың ба?
            </div>
            <el-radio-group v-model="Questions.discussAtHome">
              <el-radio label="Иә">Иә</el-radio>
              <el-radio label="Негізінен иә">Негізінен иә</el-radio>
              <el-radio label="Білмеймін">Білмеймін</el-radio>
              <el-radio label="Негізінен жоқ">Негізінен жоқ</el-radio>
              <el-radio label="Жоқ">Жоқ</el-radio>
              <el-radio label="Мен сұрақты түсінбедім">Мен сұрақты түсінбедім</el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">
              Сен талантың мен қабілетіңді анықтайтын Qabilet тестілеуінен өтіп көрдің бе?
            </div>
            <el-radio-group v-model="Questions.useQabilet">
              <el-radio label="Иә">Иә</el-radio>
              <el-radio label="Жоқ, бұл не екенін білмеймін">Жоқ, бұл не екенін білмеймін</el-radio>
              <el-radio label="Мен басқалардан білемін немесе естідім, бірақ өтпедім">Мен басқалардан білемін немесе естідім, бірақ өтпедім</el-radio>
            </el-radio-group>

            <div :class="{'error-pole-modal':Questions.howSpendFreeTime.length>3}" class="polemodalQuestions">
              Бос уақытыңды қалай өткізесің? (3 жауапты таңдауға болады)
            </div>
            <el-checkbox-group v-model="Questions.howSpendFreeTime">
              <el-checkbox label="Кітап оқимын"></el-checkbox>
              <el-checkbox label="Қосымша білім аламын, репетитормен сабақ оқимын"></el-checkbox>
              <el-checkbox label="Теледидар көремін"></el-checkbox>
              <el-checkbox label="Бос уақытымды көбіне интернетте өткіземін"></el-checkbox>
              <el-checkbox label="Достарыммен кездесемін"></el-checkbox>
              <el-checkbox label="Ұй шаруашылығына көмектесемін"></el-checkbox>
              <el-checkbox label="Мен спорттық немесе шығармашылық секцияға барамын"></el-checkbox>
              <el-checkbox label="Қонаққа барамын"></el-checkbox>
              <el-checkbox label="__other_option__">{{ $t('pole-modal.other') }}: <input v-model="Questions.textHowSpendFreeTime"
                                                                                         class="pole-modal-input"></input>
              </el-checkbox>
            </el-checkbox-group>
            <span class="error-pole-modal"
                  v-if="Questions.howSpendFreeTime.length>3">3 нұсқаны таңдауға болады</span>

            <div :class="{'error-pole-modal':Questions.internetActivity.length>3}" class="polemodalQuestions">
              Әдетте сен интернетке кіргенде немен айналысасың (3 жауап таңдауға болады)
            </div>
            <el-checkbox-group v-model="Questions.internetActivity">
              <el-checkbox label="Подкасттар мен аудиокітаптарды тыңдаймын"></el-checkbox>
              <el-checkbox label="Мен тик-ток, Instagram және т. б. парақша жүргіземін."></el-checkbox>
              <el-checkbox label="Онлайн сауда жасаймын"></el-checkbox>
              <el-checkbox label="Музыка тыңдаймын немесе жүктеймін"></el-checkbox>
              <el-checkbox label="Діни тақырып мазмұнындағы контентті тыңдаймын және қараймын"></el-checkbox>
              <el-checkbox label="Әлеуметтік желілер арқылы сөйлесу"></el-checkbox>
              <el-checkbox label="Жаңалықтарды оқимын"></el-checkbox>
              <el-checkbox label="Блогерлерді қарау"></el-checkbox>
              <el-checkbox label="Оқимын (сабақтар өқу, үй тапсырмасын орындау)"></el-checkbox>
              <el-checkbox label="Онлайн ойындар ойнаймын"></el-checkbox>
              <el-checkbox label="Фильмдер көремін"></el-checkbox>
              <el-checkbox label="Мен пайдалы ақпарат іздеймін (қызығушылықтар, хобби, қызықты сұрақтар)"></el-checkbox>
              <el-checkbox label="Мен онлайн курстар оқимын, онлайн үйірмелер мен секцияларға қатысамын"></el-checkbox>
              <el-checkbox label="__other_option__">{{ $t('pole-modal.other') }}: <input v-model="Questions.textInternetActivity"
                                                                                         class="pole-modal-input"></input>
              </el-checkbox>
            </el-checkbox-group>
            <span class="error-pole-modal"
                  v-if="Questions.internetActivity.length>3">3 нұсқаны таңдауға болады</span>

            <div class="polemodalQuestions">
              Сен қандай ойындар ойнайсың?
            </div>
            <el-radio-group v-model="Questions.whatGamesPlay">
              <el-radio label="Компьютерлік">Компьютерлік</el-radio>
              <el-radio label="Үстел үсті ойындары">Үстел үсті ойындары</el-radio>
              <el-radio label="Мобильді ойындар">Мобильді ойындар</el-radio>
              <el-radio label="Спорттық, достарыммен ойын –сауықтық">Спорттық, достарыммен ойын –сауықтық</el-radio>
              <el-radio label="Мен ойын ойнамаймын">Мен ойын ойнамаймын</el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">
              Өзің ойнайтын ойындардың атын жаз
            </div>
            <input v-model="Questions.favoriteGames" class="pole-modal-input"></input>

            <div class="polemodalQuestions">
              Мектептегі пәндерді ойын форматында оқығың келе ме?
            </div>
            <el-radio-group v-model="Questions.schoolSubjectsInGames">
              <el-radio label="Иә">Иә</el-radio>
              <el-radio label="Жоқтан гөрі иә">Жоқтан гөрі иә</el-radio>
              <el-radio label="Иә дегеннен гөрі жоқ">Иә дегеннен гөрі жоқ</el-radio>
              <el-radio label="Жоқ">Жоқ</el-radio>
              <el-radio label="Мен мұның нені білдіретінін түсінбедім">Мен мұның нені білдіретінін түсінбедім</el-radio>
            </el-radio-group>


            <div class="polemodalQuestions">
              Жасанды интеллект мүмкіндіктерін пайдаланасың ба (ChatGPT, Azure AI, Google DeepMind)
            </div>
            <el-radio-group v-model="Questions.useAI">
              <el-radio label="Иә, үнемі">Иә, үнемі</el-radio>
              <el-radio label="Иә, пайдаланып көрдім">Иә, пайдаланып көрдім</el-radio>
              <el-radio label="Жоқ">Жоқ</el-radio>
              <el-radio label="Мен мұның не екенін білмеймін">Мен мұның не екенін білмеймін</el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">
              Сен BirgeOqy онлайн мектебі туралы білесің бе?
            </div>
            <el-radio-group v-model="Questions.knowBirgeOqu">
              <el-radio label="Иә">Иә</el-radio>
              <el-radio label="Жоқ">Жоқ</el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">
              Сізде онлайн оқуға жағдай бар ма?
            </div>
            <div class="polemodalQuestions">
              Компьютер
            </div>
            <el-radio-group v-model="Questions.onlineComputer">
              <el-radio label="Бар">Бар</el-radio>
              <el-radio label="Жоқ">Жоқ</el-radio>
            </el-radio-group>
            <div class="polemodalQuestions">
              Жақсы жылдамдықтағы интернет
            </div>
            <el-radio-group v-model="Questions.onlineGoodInternet">
              <el-radio label="Бар">Бар</el-radio>
              <el-radio label="Жоқ">Жоқ</el-radio>
            </el-radio-group>
            <div class="polemodalQuestions">
              Жұмыс орны
            </div>
            <el-radio-group v-model="Questions.onlineWorkPlace">
              <el-radio label="Бар">Бар</el-radio>
              <el-radio label="Жоқ">Жоқ</el-radio>
            </el-radio-group>
            <div class="polemodalQuestions">
              Бос уақыт
            </div>
            <el-radio-group v-model="Questions.onlineComputerFreeTime">
              <el-radio label="Бар">Бар</el-radio>
              <el-radio label="Жоқ">Жоқ</el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">Сен үйірмелерге немесе секцияларға қатысасың ба?</div>
            <el-radio-group v-model="Questions.sections">
              <el-radio label="Иә (офлайн қатысамын)">Иә (офлайн қатысамын)</el-radio>
              <el-radio label="Иә (онлайн қатысамын)">Иә (онлайн қатысамын)</el-radio>
              <el-radio label="Иә (онлайн және офлайн)">Иә (онлайн және офлайн)</el-radio>
              <el-radio label="Мен қалаймын, бірақ мүмкіндігім жоқ (қосымша сұрақтарға көшу)">Мен қалаймын, бірақ мүмкіндігім жоқ (қосымша сұрақтарға көшу)</el-radio>
              <el-radio label="Қандай үйірмелер мен секциялар бар екенін білмеймін (қосымша сұрақтарға көшу)">Қандай үйірмелер мен секциялар бар екенін білмеймін (қосымша сұрақтарға көшу)</el-radio>
              <el-radio label="Маған қызық емес (қосымша сұрақтарға көшу)">Маған қызық емес (қосымша сұрақтарға көшу)</el-radio>
              <el-radio label="Жоқ (қосымша сұрақтарға көшу)">Жоқ (қосымша сұрақтарға көшу)</el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">
              Сенің қызығушылығыңды оятып, қосымша онлайн сабаққа жазылуға не ынталандыруы мүмкін?
            </div>
            <el-radio-group v-model="Questions.interestAdditionalOnlineCourses">
              <el-radio label="Жарнама">
                Жарнама
              </el-radio>
              <el-radio label="Өзін-өзі дамыту">
                Өзін-өзі дамыту
              </el-radio>
              <el-radio label="Досымның, мұғалімнің кеңесі">Досымның, мұғалімнің кеңесі</el-radio>
              <el-radio label="Үйірме тақырыбына деген қызығушылық">
                Үйірме тақырыбына деген қызығушылық
              </el-radio>
              <el-radio label="Негізгі оқу үшін пайдалы білім">
                Негізгі оқу үшін пайдалы білім
              </el-radio>
              <el-radio label="болашақ мамандығым">
                болашақ мамандығым
              </el-radio>
              <el-radio label="Сыйлықтар мен сыйақы">
                Сыйлықтар мен сыйақы
              </el-radio>
              <el-radio label="__other_option__">{{ $t('pole-modal.other') }}: <input v-model="Questions.textInterestAdditionalOnlineCourses"
                                                                                      class="pole-modal-input"></input>
              </el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">
              Егер сенде таңдау мүмкіндігі болса, саған қандай қосымша білім алу ұнар еді?
            </div>
            <el-radio-group v-model="Questions.additionalEducation">
              <el-radio label="Техникалық (бағдарламалау, модельдеу, инжиниринг және т. б.)">Техникалық (бағдарламалау, модельдеу, инжиниринг және т. б.)</el-radio>
              <el-radio label="Гуманитарлық (тарих, география, әлеуметтану және т.б.)">Гуманитарлық (тарих, география, әлеуметтану және т.б.)</el-radio>
              <el-radio label="Мектеп олимпиадаларына дайындық">Мектеп олимпиадаларына дайындық</el-radio>
              <el-radio label="Спорттық (шахмат, дойбы, туризм, жекпе-жек негіздері және т.б.)">Спорттық (шахмат, дойбы, туризм, жекпе-жек негіздері және т.б.)</el-radio>
              <el-radio label="Тұлғалық даму (ынталандыру, психология, шешендік және т.б.)">Тұлғалық даму (ынталандыру, психология, шешендік және т.б.)</el-radio>
              <el-radio label="Қаржылық (кәсіпкерлік негіздері, жеке шығындар, криптовалюталар және т.б.)">Қаржылық (кәсіпкерлік негіздері, жеке шығындар, криптовалюталар және т.б.)</el-radio>
              <el-radio label="Шығармашылық (дизайн, компьютерлік графика, музыка және т.б.)">Шығармашылық (дизайн, компьютерлік графика, музыка және т.б.)</el-radio>
              <el-radio label="Тілдер (ағылшын, қазақ және т. б.)">Тілдер (ағылшын, қазақ және т. б.)</el-radio>
            </el-radio-group>

            <div class="polemodalQuestions">
              Саған көбірек ұнайтын онлайн үйірмені таңда (технология)
            </div>
            <el-radio-group v-model="Questions.favoriteSectionOnline">
              <el-radio label="Блоктық бағдарламалау (Kodu, Minecraft, Roblox)">Блоктық бағдарламалау (Kodu, Minecraft, Roblox)</el-radio>
              <el-radio label="Бағдарламалау (Go, Python)">Бағдарламалау (Go, Python)</el-radio>
              <el-radio label="Сайттар әзірлеу">Сайттар әзірлеу</el-radio>
              <el-radio label="Робототехника">Робототехника</el-radio>
              <el-radio label="Ойындар құру (Unity және т. б.)">Ойындар құру (Unity және т. б.)</el-radio>
              <el-radio label="Ештеңе ұнамайды">Ештеңе ұнамайды</el-radio>
            </el-radio-group>


            <div class="polemodalQuestions">
              Саған көбірек ұнайтын онлайн үйірмені таңда (мектеп олимпиадаларына дайындықты)
            </div>
            <el-radio-group v-model="Questions.preparationForSchoolOlympiads">
              <el-radio label="Математика">Математика</el-radio>
              <el-radio label="Химия">Химия</el-radio>
              <el-radio label="Физика">Физика</el-radio>
              <el-radio label="Тарих">Тарих</el-radio>
              <el-radio label="География">География</el-radio>
              <el-radio label="Тілдер">Тілдер</el-radio>
              <el-radio label="Информатика">Информатика</el-radio>
              <el-radio label="Ештеңе ұнамайды">Ештеңе ұнамайды</el-radio>
            </el-radio-group>


            <div class="polemodalQuestions">
              Саған көбірек ұнайтын онлайн үйірмені таңда (тұлғалық даму)
            </div>
            <el-radio-group v-model="Questions.personalDevelopment">
              <el-radio label="Ынталандыру және жалпы даму">Ынталандыру және жалпы даму</el-radio>
              <el-radio label="Психология">Психология</el-radio>
              <el-radio label="Шешендік өнер">Шешендік өнер</el-radio>
              <el-radio label="Пікірталас">Пікірталас</el-radio>
              <el-radio label="Ештеңе ұнамайды">Ештеңе ұнамайды</el-radio>
            </el-radio-group>


            <div class="polemodalQuestions">
              Саған көбірек ұнайтын онлайн үйірмені таңда(қаржы)
            </div>
            <el-radio-group v-model="Questions.financeCourses">
              <el-radio label="Кәсіпкерлік негіздері">Кәсіпкерлік негіздері</el-radio>
              <el-radio label="Жеке шығындар">Жеке шығындар</el-radio>
              <el-radio label="Криптовалюталар және блокчейн">Криптовалюталар және блокчейн</el-radio>
              <el-radio label="Ештеңе ұнамайды">Ештеңе ұнамайды</el-radio>
            </el-radio-group>


            <div :class="{'error-pole-modal':Questions.profession.length>3}" class="polemodalQuestions">
              Болашақта кім болғың келеді? (3 жауапты таңдауға болады)
            </div>
            <el-checkbox-group v-model="Questions.profession">
              <el-checkbox label="Білмеймін">Білмеймін</el-checkbox>
              <el-checkbox label="Ғалым">Ғалым</el-checkbox>
              <el-checkbox label="Мұғалім">Мұғалім</el-checkbox>
              <el-checkbox label="Қаржыгер, банкте жұмыс істеу">Қаржыгер, банкте жұмыс істеу</el-checkbox>
              <el-checkbox label="Актер, әнші">Актер, әнші</el-checkbox>
              <el-checkbox label="Спортшы">Спортшы</el-checkbox>
              <el-checkbox label="Заңгер">Заңгер</el-checkbox>
              <el-checkbox label="Суретші">Суретші</el-checkbox>
              <el-checkbox label="Блогер, тиктокер, ютубер">Блогер, тиктокер, ютубер</el-checkbox>
              <el-checkbox label="IT-маман">IT-маман</el-checkbox>
              <el-checkbox label="Полиция, әскери">Полиция, әскери</el-checkbox>
              <el-checkbox label="Музыкант, биші">Музыкант, биші</el-checkbox>
              <el-checkbox label="Президент, депутат, министр">Президент, депутат, министр</el-checkbox>
              <el-checkbox label="Киберспортшы, геймер">Киберспортшы, геймер</el-checkbox>
              <el-checkbox label="Тележүргізуші, журналист">Тележүргізуші, журналист</el-checkbox>
              <el-checkbox label="Кәсіпкер">Кәсіпкер</el-checkbox>
              <el-checkbox label="Ғарышкер">Ғарышкер</el-checkbox>
              <el-checkbox label="Дәрігер">Дәрігер</el-checkbox>
              <el-checkbox label="__other_option__">{{ $t('pole-modal.other') }}: <input
                  v-model="Questions.textProfession" class="pole-modal-input"></input></el-checkbox>
            </el-checkbox-group>
            <span class="error-pole-modal" v-if="Questions.profession.length>3">3 нұсқаны таңдауға болады</span>

            <div :class="{'error-pole-modal':Questions.successfulInFuture.length>3}" class="polemodalQuestions">
              Саған болашақта табысты болуыңа не көмектесе алады деп ойлайсың? (3 нұсқаны таңдауға болады)
            </div>
            <el-checkbox-group v-model="Questions.successfulInFuture">
              <el-checkbox label="Басқа елге көшу">Басқа елге көшу</el-checkbox>
              <el-checkbox label="Мұғалімнің көмегі">Мұғалімнің көмегі</el-checkbox>
              <el-checkbox label="Тәрбиешінің көмегі">Тәрбиешінің көмегі</el-checkbox>
              <el-checkbox label="Лицей мектебіне қабылдау, БИЛ, Дарын">Лицей мектебіне қабылдау, БИЛ, Дарын</el-checkbox>
              <el-checkbox label="Басқа қалаға көшу">Басқа қалаға көшу</el-checkbox>
              <el-checkbox label="Ата-ананың көбірек көңіл бөлуі">Ата-ананың көбірек көңіл бөлуі</el-checkbox>
              <el-checkbox label="Компьютерлік үйірмедегі сабақтар (соның ішінде робототехника)">Компьютерлік үйірмедегі сабақтар (соның ішінде робототехника)</el-checkbox>
              <el-checkbox label="Тіл үйрену">Тіл үйрену</el-checkbox>
              <el-checkbox label="Мұғалімдер тарапынан көбірек назар аудару">Мұғалімдер тарапынан көбірек назар аудару</el-checkbox>
              <el-checkbox label="Спорттық секциялардағы немесе шығармашылық үйірмелердегі сабақтар">Спорттық секциялардағы немесе шығармашылық үйірмелердегі сабақтар</el-checkbox>
              <el-checkbox label="Мектептегі жөндеу және жабдықтар">Мектептегі жөндеу және жабдықтар</el-checkbox>
              <el-checkbox label="__other_option__">{{ $t('pole-modal.other') }}: <input
                  v-model="Questions.textSuccessfulInFuture" class="pole-modal-input"></input></el-checkbox>
            </el-checkbox-group>
            <span class="error-pole-modal" v-if="Questions.successfulInFuture.length>3">3 нұсқаны таңдауға болады</span>

            <div class="button-polemodal-wrapper">
              <el-button @click="sendForm()" type="primary">{{ $t('pole-modal.send') }}</el-button>
            </div>
          </form>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Vue from "vue";

export default {
  name: 'Navbar',
  data() {
    return {
      Questions: {
        IIN: '',
        birthDate: '',
        gender: '',
        region: '',
        district: '',
        locality: '',
        eduRegion: '',
        eduDistrict: '',
        eduLocality: '',
        typeEducationalInstitution: '',
        educationalInstitution: '',
        class: '',
        languageInstruction: '',
        testingLanguage: '',
        resultsFirstStageOlympiad: '',
        status: '',
        level: '',
        trialTest: '',
        win: '',
        schoolProblems: [],
        schoolHelper: '',
        participateInLifeClass: [],
        questionGoToSchool: '',
        friendlyClass: '',
        friendlyTeacher: '',
        favoriteSubject: '',
        notFavoriteSubject: '',
        gradesAtSchool: '',
        helpClassmates: [],
        teachersAttentiveToYou : '',
        discussAtHome: '',
        useQabilet:'',
        howSpendFreeTime: [],
        internetActivity: [],
        whatGamesPlay: '',
        useAI: '',
        knowBirgeOqu: '',
        sections: '',
        additionalEducation: '',
        favoriteSectionOnline: '',
        profession: [],
        successfulInFuture: [],
        textWin: '',
        textParticipateInLifeClass: '',
        textHelpClassmates: '',
        textHowSpendFreeTime: '',
        textProfession: '',
        textSuccessfulInFuture: '',
        textInternetActivity: '',
        onlineComputer: '',
        onlineGoodInternet: '',
        onlineWorkPlace: '',
        onlineComputerFreeTime: '',
        foundOutOlympiad: '',
        textFoundOutOlympiad: '',
        problemsWithOlympiad: '',
        problemsWithPassing: '',
        textProblemsWithPassing: '',
        favoriteGames: '',
        schoolSubjectsInGames: '',
        interestAdditionalOnlineCourses: '',
        textInterestAdditionalOnlineCourses: '',
        preparationForSchoolOlympiads: '',
        personalDevelopment: '',
        financeCourses: '',
      },
      informedCheckbox: false,
      avatar: '',
      user: {},
      showPoleButton: false,
      collapsed: true,
      hasNewNotification: false,
      isShownModal: false,
      isShownModalFormRu: false,
      isShownModalFormKz: false,
    }
  },
  created() {
    document.addEventListener("keydown", this.pressEscButton);
  },
  destroyed() {
    document.removeEventListener("keydown", this.pressEscButton);
  },
  mounted() {
    this.$http.get(window.API_ROOT + '/api/user/edit')
        .then((res) => {
          this.user = res.body.data;
          this.avatar = `${window.API_ROOT}/api/user/avatar?file=${ this.user.avatar}`;
          sessionStorage.setItem('avatar-url', `${window.API_ROOT}/api/user/avatar?file=${ this.user.avatar}`);
          const {notifications} = res.body.data.notifications
          this.hasNewNotification = this.checkNewNotification(notifications)
          this.showPoleButton = res.body.quiz_info == true && res.body.data.polled == 0
          this.showPoleButton = false
          localStorage.setItem('user', JSON.stringify(res.body.data));
          this.Questions.status='участник'
          if(res.body.data.first_stage_winner){
            this.Questions.status='финалист'
          }
          if(res.body.data.second_stage_winner){
            this.Questions.status='победитель'
          }
          if ($('body').width() >= 992) {
            if(this.showPoleButton==true){
              this.$bvModal.show('poledModal')
            }
          }

          // if (this.user.polled === 0) {
          //   this.$bvModal.show('poledModal')
          // }

          this.Questions.IIN = res.body.data.iin
          this.Questions.birthDate = res.body.data.birth_date
          this.Questions.gender = res.body.data.sex === 1 ? 'Муж' : 'Жен'
          this.Questions.region = res.body.data.region_name
          this.Questions.district = res.body.data.city_name
          this.Questions.locality = res.body.data.locality_name
          this.Questions.eduRegion = res.body.data.education.region_name
          this.Questions.eduDistrict = res.body.data.education.city_name
          this.Questions.eduLocality = res.body.data.education.location_name
          this.Questions.typeEducationalInstitution = res.body.data.school
          this.Questions.educationalInstitution = res.body.data.school
          this.Questions.class = res.body.data.class
          this.Questions.languageInstruction = res.body.data.education.instruction_language === 'kz' ? 'каз' : 'рус'
          this.Questions.testingLanguage = res.body.data.instruction_language === 'kz' ? 'каз' : 'рус'
          this.Questions.resultsFirstStageOlympiad = res.body.data.first_stage
        })

  },
  watch: {
    'Questions.textWin'() {
      this.Questions.win = '__other_option__'
    },
    'Questions.textFoundOutOlympiad'() {
      this.Questions.foundOutOlympiad = '__other_option__'
    },
    // informedCheckbox() {
    //   this.$bvModal.hide('poledModal')
    //   if (this.$i18n.locale == 'ru') {
    //     this.$bvModal.show('poledModal-2-ru')
    //   } else if (this.$i18n.locale == 'kz') {
    //     this.$bvModal.show('poledModal-2-kz')
    //   }
    //
    // }
    informedCheckbox() {

      const user = JSON.parse(localStorage.getItem('user'))
      const schoolBIN = user.education.school.bin
      const userClass = user.class
      const userRegion = user.region_name // область
      const userCity = user.city_name // район
      const userLocality = user.locality_name // населенный пункт
      const userRegionEdu = user.education.region_name // область образование
      const userCityEdu = user.education.city_name // район образование
      const userLocalityEdu = user.education.location_name // населенный пункт образование
      const schoolName = user.education.school.name
      const userFirstStage = user.first_stage // первый этап

      this.$bvModal.hide('poledModal')
      if (this.$i18n.locale == 'ru') {
        // this.$bvModal.show('poledModal-2-ru')

        const userGender = user.sex == 2 ? 'Жен' : 'Муж'
        const instructionLanguage = user.education.instruction_language == 'kz' ? 'каз' : 'рус' // язык обучения
        const testLanguage = user.education.test_language == 'kz' ? 'каз' : 'рус' // язык сдачи

        location.href = `https://docs.google.com/forms/d/e/1FAIpQLSfwlrdIaU23qzFt0QA49ok-Dy7OhuI2tmhldJ2jo8quMEL0-g/viewform?usp=pp_url&entry.1837795238=${user.iin}&entry.496385851=${user.birth_date}&entry.1331363301=${userGender}&entry.218682015=${userRegion}&entry.681155163=${userCity}&entry.1145555405=${userLocality}&entry.1274123360=${userRegionEdu}&entry.463274075=${userCityEdu}&entry.1043618168=${userLocalityEdu}&entry.1938511119=Школа&entry.137976003=${schoolBIN}&entry.1393679014=${schoolName}&entry.170288098=${userClass}&entry.294793165=${instructionLanguage}&entry.46179682=${testLanguage}&entry.1143191096=${userFirstStage}`;
      } else if (this.$i18n.locale == 'kz') {
        // this.$bvModal.show('poledModal-2-kz')

        const userGender = user.sex == 2 ? 'Әйел' : 'Ер'
        const instructionLanguage = user.education.instruction_language == 'kz' ? 'Қазақша' : 'Орысша' // язык обучения
        const testLanguage = user.education.test_language == 'kz' ? 'Қазақша' : 'Орысша' // язык сдачи

        location.href = `https://docs.google.com/forms/d/e/1FAIpQLSdg6wmaFEE4gO-t6JaPcDD3W94I_J2XRV2wI9A-bj4-PhLOKQ/viewform?usp=pp_url&entry.1837795238=${user.iin}&entry.496385851=${user.birth_date}&entry.1331363301=${userGender}&entry.218682015=${userRegion}&entry.681155163=${userCity}&entry.1145555405=${userLocality}&entry.1274123360=${userRegionEdu}&entry.463274075=${userCityEdu}&entry.1043618168=${userLocalityEdu}&entry.1938511119=Мектеп&entry.137976003=${schoolBIN}&entry.1393679014=${schoolName}&entry.170288098=${userClass}&entry.294793165=${instructionLanguage}&entry.46179682=${testLanguage}&entry.1143191096=${userFirstStage}`
      }

    }
  },
  methods: {
    onShownFormKz() {
      this.isShownModalFormKz = true
    },
    onHiddenFormKz() {
      this.isShownModalFormKz = false
    },
    onShownFormRu() {
      this.isShownModalFormRu = true
    },
    onHiddenFormRu() {
      this.isShownModalFormRu = false
    },
    onShown() {
      this.isShownModal = true
    },
    onHidden() {
      this.isShownModal = false
    },
    pressEscButton($event) {
      if ($event.key === "Escape" || $event.keyCode === 27) { // Escape
        $event.preventDefault();
        $event.stopPropagation();
        $event.stopImmediatePropagation();
        if (this.isShownModal) {
          this.$bvModal.show('poledModal')
        }
        if (this.isShownModalFormRu) {
          this.$bvModal.show('poledModal-2-ru')
        }
        if (this.isShownModalFormKz) {
          this.$bvModal.show('poledModal-2-kz')
        }
      }
    },
    sendPoleModal() {
      this.$http.post(`${window.API_ROOT}/api/user/polled`, {
        polled: 1
      })
      this.$bvModal.hide('poledModal-2-ru')
      this.$bvModal.hide('poledModal-2-kz')
    },
    openModal() {
      if (this.informedCheckbox == false) {
        this.$bvModal.show('poledModal')
      } else {
        if (this.$i18n.locale == 'ru') {
          this.$bvModal.show('poledModal-2-ru')
        } else if (this.$i18n.locale == 'kz') {
          this.$bvModal.show('poledModal-2-kz')
        }
      }
    },
    postData(url) {
      let schoolProblems = '';
      let participateInLifeClass = '';
      let helpClassmates = '';
      let howSpendFreeTime = '';
      let internetActivity = '';
      let profession = '';
      let successfulInFuture = '';
      let QuestionTextWin = '';
      let QuestionTextFoundOutOlympiad = '';
      let QuestionTextProblemsWithPassing = '';
      let QuestionTextInterestAdditionalOnlineCourses = '';


      // let sport = '';
      // let creations = '';
      // let it = '';

      let request;
      if (this.$i18n.locale == 'ru') {
        if (this.Questions.win == '__other_option__') {
          QuestionTextWin = `&entry.1479163013.other_option_response=${encodeURI(this.Questions.textWin)}`
        }

        if (this.Questions.foundOutOlympiad == '__other_option__') {
          QuestionTextFoundOutOlympiad = `&entry.832192670.other_option_response=${encodeURI(this.Questions.textFoundOutOlympiad)}`
        }

        if (this.Questions.problemsWithPassing == '__other_option__') {
          QuestionTextProblemsWithPassing = `&entry.947689401.other_option_response=${encodeURI(this.Questions.textProblemsWithPassing)}`
        }

        if (this.Questions.interestAdditionalOnlineCourses == '__other_option__') {
          QuestionTextInterestAdditionalOnlineCourses = `&entry.1135383111.other_option_response=${encodeURI(this.Questions.textInterestAdditionalOnlineCourses)}`
        }

        // for (let i = 0; i < this.Questions.internetActivity.length; i++) {
        //   internetActivity += `&entry.1709066131=${encodeURI(this.Questions.internetActivity[i])}`
        // }

        for (let i = 0; i < this.Questions.schoolProblems.length; i++) {
          schoolProblems += `&entry.2026397148=${encodeURI(this.Questions.schoolProblems[i])}`
        }

        for (let i = 0; i < this.Questions.internetActivity.length; i++) {
          if (this.Questions.internetActivity[i] == '__other_option__') {
            if (this.Questions.textInternetActivity) {
              profession += `&entry.1709066131.other_option_response=${encodeURI(this.Questions.textInternetActivity)}&entry.1709066131=${encodeURI(this.Questions.internetActivity[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            profession += `&entry.1709066131=${encodeURI(this.Questions.internetActivity[i])}`
          }
        }

        for (let i = 0; i < this.Questions.profession.length; i++) {
          if (this.Questions.profession[i] == '__other_option__') {
            if (this.Questions.textProfession) {
              profession += `&entry.1693994627.other_option_response=${encodeURI(this.Questions.textProfession)}&entry.1693994627=${encodeURI(this.Questions.profession[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            profession += `&entry.1693994627=${encodeURI(this.Questions.profession[i])}`
          }
        }

        for (let i = 0; i < this.Questions.participateInLifeClass.length; i++) {
          if (this.Questions.participateInLifeClass[i] == '__other_option__') {
            if (this.Questions.textParticipateInLifeClass) {
              participateInLifeClass += `&entry.1755508351.other_option_response=${encodeURI(this.Questions.textParticipateInLifeClass)}&entry.1755508351=${encodeURI(this.Questions.participateInLifeClass[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            participateInLifeClass += `&entry.1755508351=${encodeURI(this.Questions.participateInLifeClass[i])}`
          }
        }

        for (let i = 0; i < this.Questions.helpClassmates.length; i++) {
          if (this.Questions.helpClassmates[i] == '__other_option__') {
            if (this.Questions.textHelpClassmates) {
              helpClassmates += `&entry.1383577258.other_option_response=${encodeURI(this.Questions.textHelpClassmates)}&entry.1383577258=${encodeURI(this.Questions.helpClassmates[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            helpClassmates += `&entry.1383577258=${encodeURI(this.Questions.helpClassmates[i])}`
          }
        }

        for (let i = 0; i < this.Questions.howSpendFreeTime.length; i++) {
          if (this.Questions.howSpendFreeTime[i] == '__other_option__') {
            if (this.Questions.textHowSpendFreeTime) {
              howSpendFreeTime += `&entry.73107030.other_option_response=${encodeURI(this.Questions.textHowSpendFreeTime)}&entry.73107030=${encodeURI(this.Questions.howSpendFreeTime[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            howSpendFreeTime += `&entry.73107030=${encodeURI(this.Questions.howSpendFreeTime[i])}`
          }
        }

        for (let i = 0; i < this.Questions.successfulInFuture.length; i++) {
          if (this.Questions.successfulInFuture[i] == '__other_option__') {
            if (this.Questions.textSuccessfulInFuture) {
              successfulInFuture += `&entry.52819544.other_option_response=${encodeURI(this.Questions.textSuccessfulInFuture)}&entry.52819544=${encodeURI(this.Questions.successfulInFuture[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            successfulInFuture += `&entry.52819544=${encodeURI(this.Questions.successfulInFuture[i])}`
          }
        }

        request = `entry.1837795238=${encodeURI(this.user.iin)}` +
            `&entry.218682015=${encodeURI(this.user.region_name)}` +
            `&entry.681155163=${encodeURI(this.user.locality_name)}` +
            `&entry.1145555405=${encodeURI(this.user.city_name)}` +
            `&entry.1274123360=${encodeURI(this.user.education.region_name)}` +
            `&entry.463274075=${encodeURI(this.user.education.location_name)}` +
            `&entry.1043618168=${encodeURI(this.user.education.city_name)}` +
            `&entry.1938511119=${encodeURI('Школа')}` +
            `&entry.1393679014=${encodeURI(this.user.school)}` +
            `&entry.170288098=${encodeURI(this.user.class)}` +
            `&entry.1143191096=${encodeURI(this.user.first_stage)}` +
            `&entry.496385851_year=${encodeURI(Number(this.user.birth_date.substr(0, 4)))}` +
            `&entry.496385851_month=${encodeURI(Number(this.user.birth_date.substr(5, 2)))}` +
            `&entry.496385851_day=${encodeURI(Number(this.user.birth_date.substr(8, 2)))}` +
            `&entry.1331363301=${encodeURI(this.user.sex == 1 ? 'Муж' : 'Жен')}` +
            `&entry.294793165=${encodeURI(this.user.education.instruction_language == 'ru' ? 'рус' : 'каз')}` +
            `&entry.46179682=${encodeURI(this.user.education.test_language == 'ru' ? 'рус' : 'каз')}` +
            `&entry.2067872883=${encodeURI(this.Questions.status)}` +
            `&entry.1461016368=${encodeURI(this.Questions.level)}` +
            QuestionTextWin + QuestionTextFoundOutOlympiad + QuestionTextProblemsWithPassing + QuestionTextInterestAdditionalOnlineCourses +
            `&entry.1479163013=${encodeURI(this.Questions.win)}` +
            `&entry.947689401=${encodeURI(this.Questions.problemsWithPassing)}` +
            `&entry.1135383111=${encodeURI(this.Questions.interestAdditionalOnlineCourses)}` +
            internetActivity + schoolProblems +
            `&entry.1788527723=${encodeURI(this.Questions.onlineComputer)}` +
            `&entry.199017474=${encodeURI(this.Questions.onlineGoodInternet)}` +
            `&entry.1876300182=${encodeURI(this.Questions.onlineWorkPlace)}` +
            `&entry.1311811155=${encodeURI(this.Questions.onlineComputerFreeTime)}` +
            `&entry.832192670=${encodeURI(this.Questions.foundOutOlympiad)}` +
            `&entry.1970226956=${encodeURI(this.Questions.problemsWithOlympiad)}` +
            `&entry.423415833=${encodeURI(this.Questions.favoriteGames)}` +
            `&entry.1480640128=${encodeURI(this.Questions.schoolSubjectsInGames)}` +
            `&entry.1598480454=${encodeURI(this.Questions.financeCourses)}` +
            `&entry.734763883=${encodeURI(this.Questions.personalDevelopment)}` +
            `&entry.691557292=${encodeURI(this.Questions.preparationForSchoolOlympiads)}` +
            `&entry.978044068=${encodeURI(this.Questions.favoriteSectionOnline)}` +
            `&entry.1006259439=${encodeURI(this.Questions.additionalEducation)}` +
            `&entry.938452812=${encodeURI(this.Questions.knowBirgeOqu)}` +
            `&entry.1365472241=${encodeURI(this.Questions.useAI)}` +
            `&entry.706780984=${encodeURI(this.Questions.whatGamesPlay)}` +
            `&entry.1696244571=${encodeURI(this.Questions.useQabilet)}` +
            `&entry.1036317958=${encodeURI(this.Questions.discussAtHome)}` +
            `&entry.1314500320=${encodeURI(this.Questions.teachersAttentiveToYou)}` +
            `&entry.1330345380=${encodeURI(this.Questions.gradesAtSchool)}` +
            `&entry.2040237916=${encodeURI(this.Questions.notFavoriteSubject)}` +
            `&entry.803174079=${encodeURI(this.Questions.favoriteSubject)}` +
            `&entry.98092028=${encodeURI(this.Questions.schoolHelper)}` +
            `&entry.1466940510=${encodeURI(this.Questions.trialTest)}` +
            `&entry.536796658=${encodeURI(this.Questions.questionGoToSchool)}` +
            `&entry.1231476756=${encodeURI(this.Questions.friendlyClass)}` +
            `&entry.1178640644=${encodeURI(this.Questions.friendlyTeacher)}` +
            `&entry.1602905380=${encodeURI(this.Questions.sections)}` +
            profession + participateInLifeClass + helpClassmates + howSpendFreeTime + successfulInFuture
        // `&entry.1837795238_sentinel=&entry.1598480454_sentinel=&entry.734763883_sentinel=&entry.691557292_sentinel=&entry.1135383111_sentinel=&entry.1383577258_sentinel=&entry.947689401_sentinel=&entry.218682015_sentinel=&entry.681155163_sentinel=&entry.1145555405_sentinel=&entry.1274123360_sentinel=&entry.463274075_sentinel=&entry.1043618168_sentinel=&entry.1938511119_sentinel=&entry.1393679014_sentinel=&entry.170288098_sentinel=&entry.1143191096_sentinel=&entry.496385851_sentinel=&entry.1331363301_sentinel=&entry.294793165_sentinel=&entry.46179682_sentinel=&entry.2067872883_sentinel=&entry.1461016368_sentinel=&entry.1479163013_sentinel=&entry.1709066131_sentinel=&entry.2026397148_sentinel=&entry.1788527723_sentinel=&entry.199017474_sentinel=&entry.1876300182_sentinel=&entry.1311811155_sentinel=&entry.832192670_sentinel=&entry.1970226956_sentinel=&entry.1507977743_sentinel=&entry.423415833_sentinel=&entry.1480640128_sentinel=&entry.1491545666_sentinel=&entry.978044068_sentinel=&entry.1006259439_sentinel=&entry.938452812_sentinel=&entry.1365472241_sentinel=&entry.706780984_sentinel=&entry.1696244571_sentinel=&entry.1036317958_sentinel=&entry.1314500320_sentinel=&entry.1330345380_sentinel=&entry.2040237916_sentinel=&entry.803174079_sentinel=&entry.98092028_sentinel=&entry.1466940510_sentinel=&entry.536796658_sentinel=&entry.1231476756_sentinel=&entry.1178640644_sentinel=&entry.1602905380_sentinel=&entry.1693994627_sentinel=&entry.1755508351_sentinel=&entry.73107030_sentinel=&entry.52819544_sentinel=&fvv=1&partialResponse=[null,null,"-5225949193422866492"]&pageHistory=0&fbzx=-5225949193422866492`;


      } else if (this.$i18n.locale == 'kz') {

        if (this.Questions.win == '__other_option__') {
          QuestionTextWin = `&entry.1479163013.other_option_response=${encodeURI(this.Questions.textWin)}`
        }

        if (this.Questions.foundOutOlympiad == '__other_option__') {
          QuestionTextFoundOutOlympiad = `&entry.741777480.other_option_response=${encodeURI(this.Questions.textFoundOutOlympiad)}`
        }

        if (this.Questions.problemsWithPassing == '__other_option__') {
          QuestionTextProblemsWithPassing = `&entry.1371282706.other_option_response=${encodeURI(this.Questions.textProblemsWithPassing)}`
        }

        if (this.Questions.interestAdditionalOnlineCourses == '__other_option__') {
          QuestionTextInterestAdditionalOnlineCourses = `&entry.1135383111.other_option_response=${encodeURI(this.Questions.textInterestAdditionalOnlineCourses)}`
        }

        for (let i = 0; i < this.Questions.internetActivity.length; i++) {
          if (this.Questions.internetActivity[i] == '__other_option__') {
            if (this.Questions.textInternetActivity) {
              profession += `&entry.1709066131.other_option_response=${encodeURI(this.Questions.textInternetActivity)}&entry.1709066131=${encodeURI(this.Questions.internetActivity[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            profession += `&entry.1709066131=${encodeURI(this.Questions.internetActivity[i])}`
          }
        }

        for (let i = 0; i < this.Questions.schoolProblems.length; i++) {
          schoolProblems += `&entry.2026397148=${encodeURI(this.Questions.schoolProblems[i])}`
        }

        for (let i = 0; i < this.Questions.profession.length; i++) {
          if (this.Questions.profession[i] == '__other_option__') {
            if (this.Questions.textProfession) {
              profession += `&entry.1693994627.other_option_response=${encodeURI(this.Questions.textProfession)}&entry.1693994627=${encodeURI(this.Questions.profession[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            profession += `&entry.1693994627=${encodeURI(this.Questions.profession[i])}`
          }
        }

        for (let i = 0; i < this.Questions.participateInLifeClass.length; i++) {
          if (this.Questions.participateInLifeClass[i] == '__other_option__') {
            if (this.Questions.textParticipateInLifeClass) {
              participateInLifeClass += `&entry.1755508351.other_option_response=${encodeURI(this.Questions.textParticipateInLifeClass)}&entry.1755508351=${encodeURI(this.Questions.participateInLifeClass[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            participateInLifeClass += `&entry.1755508351=${encodeURI(this.Questions.participateInLifeClass[i])}`
          }
        }

        for (let i = 0; i < this.Questions.helpClassmates.length; i++) {
          if (this.Questions.helpClassmates[i] == '__other_option__') {
            if (this.Questions.textHelpClassmates) {
              helpClassmates += `&entry.1383577258.other_option_response=${encodeURI(this.Questions.textHelpClassmates)}&entry.1383577258=${encodeURI(this.Questions.helpClassmates[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            helpClassmates += `&entry.1383577258=${encodeURI(this.Questions.helpClassmates[i])}`
          }
        }

        for (let i = 0; i < this.Questions.howSpendFreeTime.length; i++) {
          if (this.Questions.howSpendFreeTime[i] == '__other_option__') {
            if (this.Questions.textHowSpendFreeTime) {
              howSpendFreeTime += `&entry.73107030.other_option_response=${encodeURI(this.Questions.textHowSpendFreeTime)}&entry.73107030=${encodeURI(this.Questions.howSpendFreeTime[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            howSpendFreeTime += `&entry.73107030=${encodeURI(this.Questions.howSpendFreeTime[i])}`
          }
        }

        for (let i = 0; i < this.Questions.successfulInFuture.length; i++) {
          if (this.Questions.successfulInFuture[i] == '__other_option__') {
            if (this.Questions.textSuccessfulInFuture) {
              successfulInFuture += `&entry.52819544.other_option_response=${encodeURI(this.Questions.textSuccessfulInFuture)}&entry.52819544=${encodeURI(this.Questions.successfulInFuture[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            successfulInFuture += `&entry.52819544=${encodeURI(this.Questions.successfulInFuture[i])}`
          }
        }

        request = `entry.1837795238=${encodeURI(this.user.iin)}` +
            `&entry.218682015=${encodeURI(this.user.region_name)}` +
            `&entry.681155163=${encodeURI(this.user.locality_name)}` +
            `&entry.1145555405=${encodeURI(this.user.city_name)}` +
            `&entry.1274123360=${encodeURI(this.user.education.region_name)}` +
            `&entry.463274075=${encodeURI(this.user.education.location_name)}` +
            `&entry.1043618168=${encodeURI(this.user.education.city_name)}` +
            `&entry.1938511119=${encodeURI('Мектеп')}` +
            `&entry.1393679014=${encodeURI(this.user.school)}` +
            `&entry.170288098=${encodeURI(this.user.class)}` +
            `&entry.1143191096=${encodeURI(this.user.first_stage)}` +
            `&entry.496385851_year=${encodeURI(Number(this.user.birth_date.substr(0, 4)))}` +
            `&entry.496385851_month=${encodeURI(Number(this.user.birth_date.substr(5, 2)))}` +
            `&entry.496385851_day=${encodeURI(Number(this.user.birth_date.substr(8, 2)))}` +
            `&entry.1331363301=${encodeURI(this.user.sex == 1 ? 'Муж' : 'Жен')}` +
            `&entry.294793165=${encodeURI(this.user.education.instruction_language == 'ru' ? 'рус' : 'каз')}` +
            `&entry.46179682=${encodeURI(this.user.education.test_language == 'ru' ? 'рус' : 'каз')}` +
            `&entry.2067872883=${encodeURI(this.Questions.status)}` +
            `&entry.1461016368=${encodeURI(this.Questions.level)}` +
            QuestionTextWin + QuestionTextFoundOutOlympiad + QuestionTextProblemsWithPassing + QuestionTextInterestAdditionalOnlineCourses +
            `&entry.1479163013=${encodeURI(this.Questions.win)}` +
            `&entry.1371282706=${encodeURI(this.Questions.problemsWithPassing)}` +
            `&entry.1135383111=${encodeURI(this.Questions.interestAdditionalOnlineCourses)}` +
            internetActivity + schoolProblems +
            `&entry.1788527723=${encodeURI(this.Questions.onlineComputer)}` +
            `&entry.658415709=${encodeURI(this.Questions.onlineGoodInternet)}` +
            `&entry.539609065=${encodeURI(this.Questions.onlineWorkPlace)}` +
            `&entry.1345986733=${encodeURI(this.Questions.onlineComputerFreeTime)}` +
            `&entry.741777480=${encodeURI(this.Questions.foundOutOlympiad)}` +
            `&entry.928729043=${encodeURI(this.Questions.problemsWithOlympiad)}` +
            `&entry.423415833=${encodeURI(this.Questions.favoriteGames)}` +
            `&entry.1480640128=${encodeURI(this.Questions.schoolSubjectsInGames)}` +
            `&entry.1598480454=${encodeURI(this.Questions.financeCourses)}` +
            `&entry.734763883=${encodeURI(this.Questions.personalDevelopment)}` +
            `&entry.691557292=${encodeURI(this.Questions.preparationForSchoolOlympiads)}` +
            `&entry.978044068=${encodeURI(this.Questions.favoriteSectionOnline)}` +
            `&entry.1006259439=${encodeURI(this.Questions.additionalEducation)}` +
            `&entry.938452812=${encodeURI(this.Questions.knowBirgeOqu)}` +
            `&entry.1365472241=${encodeURI(this.Questions.useAI)}` +
            `&entry.706780984=${encodeURI(this.Questions.whatGamesPlay)}` +
            `&entry.1696244571=${encodeURI(this.Questions.useQabilet)}` +
            `&entry.1036317958=${encodeURI(this.Questions.discussAtHome)}` +
            `&entry.1314500320=${encodeURI(this.Questions.teachersAttentiveToYou)}` +
            `&entry.1330345380=${encodeURI(this.Questions.gradesAtSchool)}` +
            `&entry.2040237916=${encodeURI(this.Questions.notFavoriteSubject)}` +
            `&entry.803174079=${encodeURI(this.Questions.favoriteSubject)}` +
            `&entry.98092028=${encodeURI(this.Questions.schoolHelper)}` +
            `&entry.1466940510=${encodeURI(this.Questions.trialTest)}` +
            `&entry.536796658=${encodeURI(this.Questions.questionGoToSchool)}` +
            `&entry.1231476756=${encodeURI(this.Questions.friendlyClass)}` +
            `&entry.1178640644=${encodeURI(this.Questions.friendlyTeacher)}` +
            `&entry.1602905380=${encodeURI(this.Questions.sections)}` +
            profession + participateInLifeClass + helpClassmates + howSpendFreeTime + successfulInFuture


      }


      fetch(url, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },

        body: request


      })
      Vue.toastr({
        message: this.$t('pole-modal.success'),
        description: this.$t('pole-modal.success-survey'),
        type: 'success'
      })
      this.sendPoleModal()
      if (this.$i18n.locale == 'ru') {
        this.$bvModal.hide('poledModal-2-ru')
      } else if (this.$i18n.locale == 'kz') {
        this.$bvModal.hide('poledModal-2-kz')
      }
      this.showPoleButton = false


    },
    sendForm() {
      let url;
      let form = JSON.stringify(this.Questions)
      form = JSON.parse(form)
      // delete form.textWin
      // delete form.textIt
      // delete form.textCreations
      // delete form.textSport
      // delete form.textProfession
      delete form.textWin
      delete form.textParticipateInLifeClass
      delete form.textHelpClassmates
      delete form.textHowSpendFreeTime
      delete form.textProfession
      delete form.textSuccessfulInFuture
      delete form.textFoundOutOlympiad
      delete form.textProblemsWithPassing
      delete form.textInterestAdditionalOnlineCourses
      delete form.textInternetActivity


      function checkElement(el) {
        return el != '';
      }

      if (Object.values(form).every(checkElement) && this.Questions.schoolProblems.length <= 3 && this.Questions.helpClassmates.length <= 3 && this.Questions.participateInLifeClass.length <= 3 && this.Questions.howSpendFreeTime.length <= 3 && this.Questions.internetActivity.length <= 3 && this.Questions.profession.length <= 3 && this.Questions.successfulInFuture.length <= 3) {
        if (this.$i18n.locale == 'ru') {
          url = 'https://docs.google.com/forms/d/e/1FAIpQLScpyLPf5kgbmYpU-XIP7_vK9_c4-HKQ_OxE33mcBfcI6Bp_WQ/formResponse'
        } else if (this.$i18n.locale == 'kz') {
          url = 'https://docs.google.com/forms/d/e/1FAIpQLScMZG_gQfi8Z0ugA2XuGQedjhFZdvRMx90gY1-9YbWTYkHIUQ/formResponse'
        }

        this.postData(url);
      } else {
        Vue.toastr({
          message: this.$t('pole-modal.warning'),
          description: this.$t('pole-modal.required'),
          type: 'error'
        })
      }
    },
    logoutMethod() {
      this.logout()
    },
    clickaway() {
      this.collapsed = true
    },
    checkNewNotification(notifications) {
      if (Array.isArray(notifications)) {
        for (let i = 0; i < notifications.length; i++) {
          if (notifications[i].is_viewed === 0) {
            return true
          }
        }
        return false
      }
    },
    ...mapActions({
      'logout': 'logout'
    })
  }
}
</script>
<style lang="css">
.confirm-pole-modal {
  margin-top: 1rem;
  text-align: center !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  color: #2C2C2C !important;
}

.confirm-pole-modal span {
  text-align: center !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  color: #2C2C2C !important;
}

.pole-modal-content {
  display: flex !important;
  flex-direction: column;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
}

.close {
  right: -1rem !important;
  left: unset;
}

</style>
<style lang="css" scoped>

.el-checkbox-group {
  display: flex;
  flex-direction: column;
}

.modal #dialog div {
  position: relative;
  z-index: 1;
  width: 100%;
}

.el-radio, .el-radio__input {
  white-space: unset;
}

.el-checkbox, .el-checkbox__input {
  text-align: initial;

}

.title-polemodal {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 40px;
  text-align: center;
  color: #05458C;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.pole-modal-title {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  text-align: center;

  color: #05458C;

}

.el-radio {
  display: flex;
}

.el-radio-group {
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.el-checkbox-group {
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.error-pole-modal {
  color: red;
  border-bottom: 1px solid red;
  padding-bottom: 10px;
  width: max-content !important;
}

.iin_input {
  margin: 0 !important;
  width: fit-content;
}
</style>
